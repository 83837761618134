import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  Suspense,
  useMemo
} from "react";
import { FloatingHTMLOverlay } from "./FloatingHTMLOverlay";
import useOnClickOutside from "~/hooks/useClickOutside";
import { useDebounce } from "~/hooks/useDebounce";
import type { TenorCategory, TenorImage } from "~/utils/tenor";
import Images from "./format/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faSearch,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useAppStore } from "~/store";

interface TenorGifPickerProps {
  key: "thread" | "publish";
  handleGifClick: (gif: any) => void;
  setGifsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const TenorGifPicker = React.memo(
  ({ key, handleGifClick, setGifsActive }: TenorGifPickerProps) => {
    const gifPickerRef = useRef<HTMLDivElement>(null);

    const tenor = useAppStore(store => store.utilities.tenor);

    const [isTrending, setIsTrending] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const debouncedQuery = useDebounce(searchQuery, 500);

    const [categoriesData, setCategoriesData] = useState<TenorCategory[]>([]);
    const [trendingData, setTrendingData] = useState<TenorImage[] | undefined>(
      []
    );
    const [searchData, setSearchData] = useState<TenorImage[] | undefined>(
      undefined
    );

    // get categories and trending gifs
    useEffect(() => {
      void (async function () {
        const categories = await tenor.categories();
        const trending = await tenor.trending(1);

        setCategoriesData(categories);
        setTrendingData(trending.images);
      })();
    }, [tenor, key]);

    // search gifs
    useEffect(() => {
      setSearchData(undefined);
      if (!debouncedQuery) return;

      void (async function () {
        const search = await tenor.search(debouncedQuery, 50);

        setSearchData(search.images);
      })();
    }, [debouncedQuery, tenor]);

    // search trending
    useEffect(() => {
      if (!isTrending) return;

      setTrendingData(undefined);

      void (async function () {
        const trending = await tenor.trending(50);

        setTrendingData(trending.images);
      })();
    }, [isTrending, tenor]);

    // on category click
    const handleCategoryClick = async (category: TenorCategory) => {
      setSearchQuery(category.name);
    };

    useOnClickOutside(gifPickerRef, () => setGifsActive(false));

    return (
      <FloatingHTMLOverlay
        onClick={ev => ev.stopPropagation()}
        className="bg-black/30 z-[1000] flex justify-center py-[6vh]"
        lockScroll
      >
        <div
          key={key}
          ref={gifPickerRef}
          className="flex flex-col w-[30rem] pc:w-3/12 pc:min-w-[440px] h-fit rounded-xl bg-pri dark:bg-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_20%)] overflow-hidden"
        >
          <header className="flex items-center justify-between gap-x-5 p-4 pb-0">
            <div className="relative flex flex-1 items-center">
              <button
                type="button"
                className={classNames(
                  "flex transition-all duration-150 visibility-hidden opacity-0 w-0 p-0",
                  {
                    "w-7 pr-3 visibility-visible opacity-100":
                      debouncedQuery || isTrending
                  }
                )}
                onClick={() => {
                  setIsTrending(false);
                  setSearchQuery("");
                }}
              >
                <FontAwesomeIcon icon={faArrowLeftLong} />
              </button>
              <span
                className={classNames(
                  "absolute flex left-3 transition-all duration-150",
                  {
                    "left-[36px]": debouncedQuery,
                    "opacity-0 visibility-hidden": isTrending
                  }
                )}
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="text-sm text-gray-400 dark:text-zinc-500"
                />
              </span>
              {isTrending ? (
                <span className="text-sm text-pri dark:text-pri-d font-semibold">
                  Trending
                </span>
              ) : (
                <input
                  className="w-full h-10 bg-transparent border border-pri dark:border-pri-d rounded-lg py-2.5 px-3.5 pl-8 font-medium text-sm text-pri dark:text-pri-d placeholder:text-gray-400 dark:placeholder:text-zinc-500 transition-all duration-150"
                  placeholder="Search GIFs, Categories, Keywords..."
                  value={searchQuery}
                  onChange={ev => setSearchQuery(ev.target.value)}
                />
              )}
            </div>

            <button
              type="button"
              className="flex w-9 h-9 items-center justify-center rounded-full border border-pri dark:border-pri-d text-pri dark:text-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-opacity duration-150"
              onClick={() => setGifsActive(false)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </header>

          <main className="flex flex-1 flex-col p-4">
            <div className="relative flex flex-col w-full h-[600px] overflow-hidden">
              <Suspense fallback={<div>Loading...</div>}>
                <div className="relative columns-2 gap-3 overflow-y-auto">
                  {searchQuery || debouncedQuery ? (
                    searchData && searchData.length > 0 ? (
                      searchData.map((gif, index) => (
                        <TenorItem
                          gif={gif}
                          key={index}
                          handleGifClick={handleGifClick}
                        />
                      ))
                    ) : searchQuery !== debouncedQuery ? (
                      [...Array(10).keys()].map(index => (
                        <TenorLoader key={index} />
                      ))
                    ) : (
                      // no results
                      [...Array(10).keys()].map(index => (
                        <TenorLoader key={index} />
                      ))
                    )
                  ) : isTrending && trendingData && trendingData.length > 0 ? (
                    trendingData.map((gif, index) => (
                      <TenorItem
                        gif={gif}
                        key={index}
                        handleGifClick={handleGifClick}
                      />
                    ))
                  ) : (
                    <React.Fragment>
                      {trendingData && trendingData.length > 0 && (
                        <TenorItem
                          gif={trendingData[0]}
                          title="Trending"
                          toggleTrending={() => setIsTrending(true)}
                        />
                      )}

                      {categoriesData.length > 0
                        ? categoriesData.map((c, index) => (
                            <TenorCategoryItem
                              gif={c}
                              key={index}
                              onClick={handleCategoryClick}
                            />
                          ))
                        : [...Array(10).keys()].map(index => (
                            <TenorLoader key={index} />
                          ))}
                    </React.Fragment>
                  )}
                </div>
              </Suspense>
            </div>
          </main>
        </div>
      </FloatingHTMLOverlay>
    );
  }
);

TenorGifPicker.displayName = "TenorGifPicker";
export default TenorGifPicker;

const TenorCategoryItem = React.memo(
  ({
    gif,
    onClick
  }: {
    gif: TenorCategory;
    onClick: (gif: TenorCategory) => void;
  }) => {
    return (
      <button
        type="button"
        className="relative flex flex-1 w-full max-w-1/2 min-w-[140px] pb-3 p-[2px] cursor-pointer break-inside overflow-hidden"
        onClick={() => onClick(gif)}
      >
        <div className="relative flex flex-1 rounded-lg overflow-hidden outline outline-2 outline-transparent transition-colors hover:outline-pri-d dark:hover:outline-pri">
          <div className="relative w-full bg-gray-300 dark:bg-zinc-700">
            <img
              src={gif.image}
              loading="lazy"
              className="max-w-full max-h-full object-cover"
              alt=""
            />
          </div>
          <div className="absolute inset-0 px-3 w-full h-full flex items-center justify-center text-center text-white font-semibold whitespace-nowrap text-ellipsis text-md bg-black/60 z-10 transition-colors duration-150 hover:bg-black/75">
            <span className="flex z-10 text-white">{gif.name}</span>
          </div>
        </div>
      </button>
    );
  }
);

TenorCategoryItem.displayName = "TenorCategory";

const TenorItem = React.memo(
  ({
    gif,
    title,
    handleGifClick,
    toggleTrending
  }: {
    gif: TenorImage;
    title?: string;
    handleGifClick?: (gif: any) => void;
    toggleTrending?: () => void;
  }) => {
    return (
      <button
        type="button"
        className="relative flex flex-1 w-full max-w-1/2 min-w-[140px] pb-3 p-[2px] cursor-pointer break-inside overflow-hidden"
        onClick={() => {
          if (toggleTrending) {
            return toggleTrending();
          }

          if (handleGifClick) {
            return handleGifClick(gif);
          }
        }}
      >
        <div className="relative flex flex-1 rounded-lg overflow-hidden outline outline-2 outline-transparent transition-colors hover:outline-pri-d dark:hover:outline-pri">
          <div
            className="relative w-full h-0 bg-gray-300 dark:bg-zinc-700"
            style={{
              paddingBottom: `${
                (gif.preview.height / gif.preview.width) * 100
              }%`
            }}
          >
            <img
              src={gif.preview.url}
              loading="lazy"
              className="absolute inset-0 w-full h-full object-cover"
              alt=""
            />
          </div>
          {title && (
            <span className="absolute inset-0 px-3 w-full h-full flex items-center justify-center text-center text-white font-semibold whitespace-nowrap text-ellipsis text-lg bg-black/60 z-10">
              {title}
            </span>
          )}
        </div>
      </button>
    );
  }
);

TenorItem.displayName = "TenorItem";

const TenorLoader = React.memo(() => {
  const height = useMemo(() => {
    return Math.floor(Math.random() * (300 - 100 + 1) + 100);
  }, []);

  return (
    <div
      className="relative flex flex-1 w-full max-w-1/2 min-w-[140px] mb-3 rounded-lg bg-gray-300 dark:bg-zinc-700 break-inside animate-pulse overflow-hidden"
      style={{ height }}
    />
  );
});

TenorLoader.displayName = "TenorLoader";

