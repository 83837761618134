import type { Plugin } from "unified";
import type { Root } from "mdast";
import type { Handler } from "mdast-util-to-hast/lib";
import type { AuthorPerm } from "~/utils/hive";
import type { HTMLProps, ReactElement } from "react";
import { unified } from "unified";
import { SKIP, visit } from "unist-util-visit";
import { visitParents } from "unist-util-visit-parents";
import { u } from "unist-builder";
import { h } from "hastscript";
import rehypeSanitize, { defaultSchema } from "rehype-sanitize";
import { stringToAuthorPerm } from "~/utils/hive";
import { createElement, Fragment, useMemo } from "react";
import rehypeReact from "rehype-react";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
import rehypeRaw from "rehype-raw";
import rehypeStringify from "rehype-stringify";
import remarkGfm from "remark-gfm";
import Anchor from "../components/format/Anchor";
import Hashtag from "../components/format/Hashtag";
import Mention from "../components/format/Mention";
import { PostPreview } from "~/components/format/PostMisc";
import { sliceString } from "./parse";
import Ticker, { tickerNames } from "~/components/format/Ticker";
import { YoutubeEmbed } from "~/components/format/YoutubeEmbed";
import { SpotifyEmbed } from "~/components/format/SpotifyEmbed";
import Images from "~/components/format/Images";
import ReThread from "~/components/format/Thread";
import ExternalFrame from "~/components/format/ExternalFrame";
import { match } from "./rustlike";
import remarkBreaks from "remark-breaks";
import CustomParagraph from "~/components/format/CustomParagraph";

const LINK_REGEX = new RegExp(/(\bhttps:\/\/\S+)/g);

const HASHTAG_MENTION_REGEX = new RegExp(
  /(#[0-9a-zA-Z_]+|\B@\w[\w.-]*\w|\$[A-Z]+)/g
);

const POST_REGEX = new RegExp(
  /https:\/\/(peakd\.com|hive\.blog|leofinance\.io|inleo\.io|ecency\.com)\/\S*@\S+\/\S+/
);
const YOUTUBE_REGEX = new RegExp(
  /(?:https?:)?(?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gim
);
const HTML_REGEX = new RegExp(/<[^>]+>/g);
const OL_UL_REGEX = new RegExp(/<:?>|(.*)|<[ol]|[li][ul]>/g);

export function isImageLink(value: string): boolean {
  return /\.(jpg|jpeg|png|gif|bmp|webp|svg|tiff|ico)$/i.test(value);
}

export function isThreadLink(value: string): boolean {
  if (typeof value !== "string") return false;

  return (
    value?.startsWith("https://leofinance.io/threads/view/") ||
    value?.startsWith("https://inleo.io/threads/view/") ||
    value?.startsWith("https://labs.leofinance.io/threads/view/") ||
    value?.startsWith("https://labs.inleo.io/threads/view/")
  );
}

export function isPostLink(value: string): boolean {
  return POST_REGEX.test(value);
}

export function isYoutubeLink(value: string): boolean {
  const result =
    value?.startsWith("https://youtu.be") ||
    value?.startsWith("https://www.youtube.com") ||
    value?.startsWith("https://youtube.com");
  return result;
}

export function isYoutubeVideoLink(value: string): boolean {
  return ["https://www.youtube.com/watch?v=", "https://youtu.be/"]?.some(
    prefix => value?.startsWith(prefix)
  );
}

export function convertYoutubeLinkToEmbed(youtubeLink: string): string {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = youtubeLink.match(regex);
  const videoID = match![1];
  if (youtubeLink.includes("shorts")) {
    return `<iframe width="315" height="560" src="https://www.youtube.com/embed/${videoID}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
  }
  return `<iframe width="100%" height="315" src="https://www.youtube.com/embed/${videoID}" frameborder="0" allowfullscreen></iframe>`;
}

export function isSpotifyLink(value: string): boolean {
  return value?.startsWith("https://open.spotify.com/track/");
}

function isIframe(value: string): boolean {
  const regex = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/;
  return regex.test(value);
}

function toImage(value: string) {
  return u("imagel", {
    src: value
  });
}

function toLink(value: string) {
  return u("link", {
    url: value,
    title: value,
    children: [u("text", value)]
  });
}

function toYoutube(url: string) {
  let videoId = "";
  if (url.startsWith("https://youtu.be")) {
    videoId = url.replace("https://youtu.be/", "").slice(0, 11);
  } else if (url.startsWith("https://www.youtube.com/watch?v=")) {
    videoId = url.replace("https://www.youtube.com/watch?v=", "").slice(0, 11);
  } else if (url.startsWith("https://youtube.com/shorts/")) {
    videoId = url.replace("https://youtube.com/shorts/", "").split("?")[0];
  }
  return u("youtube", videoId);
}

function toSpotify(value: string) {
  return u("spotify", value.split("/track/")[1].split("?")[0]);
}

function toThread(value: AuthorPerm) {
  return u("thread", value);
}

export const leomark: Plugin<[], Root> = () => {
  return tree => {
    visitParents(tree, "text", (node, ancestors) => {
      const { value } = node;
      if (value.search(LINK_REGEX) === -1) {
        return;
      }

      const nodes = value.split(LINK_REGEX).flatMap<any>((text: string) => {
        if (text.length === 0) {
          return [];
        }
        if (!text?.startsWith("https://")) {
          return [u("text", text)];
        }
        if (isImageLink(text)) {
          return [];
          //return [toImage(text)];
        }
        if (isYoutubeLink(text)) {
          return [toYoutube(text)];
        }
        if (isSpotifyLink(text)) {
          return [toSpotify(text)];
        }
        if (isThreadLink(text)) {
          const [author, permlink] = text.split("/threads/view/")[1].split("/");
          return [toThread({ author, permlink } as AuthorPerm)];
        }
        if (isPostLink(text)) {
          const postRelated = text.split("@")[1];
          const { author, permlink } = stringToAuthorPerm(postRelated);

          const grandParent = ancestors[1];
          const grandParentIndex = tree.children.indexOf(grandParent as any);

          // impure but works
          tree.children.splice(
            grandParentIndex + 1,
            0,
            u("post", { url: text, author, permlink }) as any
          );

          return [];
        }
        return [toLink(text)];
      });

      const parent = ancestors[ancestors.length - 1];
      const i = parent.children.indexOf(node as any);

      parent.children.splice(i, 1, ...nodes);

      return [SKIP, i + nodes.length];
    });

    visit(tree, "text", ({ value }, i, { children }: any) => {
      if (value.search(HASHTAG_MENTION_REGEX) === -1) {
        return;
      }

      const nodes = value
        .split(HASHTAG_MENTION_REGEX)
        .flatMap((text: string) => {
          if (text.length === 0) {
            return [];
          }
          if (text[0] === "#" && text[1] !== "#" && text[1] !== " ") {
            return [u("hashtag", text.slice(1))];
          }
          if (text[0] === "@") {
            return [u("mention", text.slice(1))];
          }
          if (text[0] === "$") {
            return [u("ticker", text.slice(1))];
          }
          return [u("text", text)];
        });

      children.splice(i, 1, ...nodes);

      return [SKIP, i];
    });
  };
};

export function removeHTMLTags(value: string) {
  if (!value) return value;
  return value.replaceAll(HTML_REGEX, "");
}

export const stripMarkdownToText: Plugin<[], Root> = () => {
  return tree => {
    visit(tree, ({ type, value, tagName, children }: any, i, parent) => {
      if (parent === undefined) {
        return;
      }
      if (parent === null) {
        return;
      }
      if (type === "text" || tagName === "p") {
        if (value !== undefined && value.search(LINK_REGEX) === -1) {
          return;
        }
      }
      if (Array.isArray(children)) {
        parent.children.splice(i, 1, ...children);
      } else {
        parent.children.splice(i, 1);
      }
      return [SKIP, i];
    });
  };
};

export const leomarkHandler: Handler = (_n, node) => {
  switch (node.type) {
    case "hashtag":
      return h("hashtag", { hashtag: node.value });
    case "post":
      return h("post", {
        url: node.url,
        author: node.author,
        permlink: node.permlink
      });
    case "mention":
      return h("mention", { mention: node.value });
    case "ticker":
      if (Object.keys(tickerNames).includes(node.value)) {
        return h("ticker", { ticker: node.value });
      } else return h("text", "$" + node.value);
    case "youtube":
      return h("youtube", { embedURI: node.value });
    case "spotify":
      return h("spotify", { track: node.value });
    case "imagel":
      return h("imagel", { src: node.src });
    case "thread":
      return h("thread", { author: node.author, permlink: node.permlink });
    case "iframe":
      return h("iframe", { src: node.src });
  }
};

const convertYoutubeLink = (link: string) => {
  return `https://i.ytimg.com/vi/${link
    .replaceAll("https://www.youtube.com/watch?v=", "")
    .replaceAll("https://www.youtube.com/embed/", "")
    .replaceAll('"', "")
    .replaceAll("https://youtu.be/", "")}/hqdefault.jpg`;
};

export const leomarkSanitizeSchema = {
  ...defaultSchema,
  tagNames: [
    ...defaultSchema.tagNames!,
    "hashtag",
    "mention",
    "post",
    "ticker",
    "spotify",
    "youtube",
    "imagel",
    "thread",
    "iframe"
  ],
  attributes: {
    ...defaultSchema.attributes!,
    imagel: ["src"],
    hashtag: ["hashtag"],
    mention: ["mention"],
    ticker: ["ticker"],
    post: ["url", "author", "permlink"],
    spotify: ["track"],
    youtube: ["embedURI"],
    thread: ["author", "permlink"]
  }
};

// for building hive comment metadata. we use only images for post previews as of now,
// while other interfaces may also use links.
export function buildLinksImagesArray(
  body: string
): { links: string[]; images: string[] } | null {
  const linksImages = body.match(LINK_REGEX);

  if (linksImages === null) {
    return null;
  }

  const links = [] as string[];
  const images = [] as string[];

  for (const entry of linksImages) {
    if (isImageLink(entry)) {
      images.push(entry);
    } else if (isYoutubeLink(entry)) {
      images.push(convertYoutubeLink(entry));
    } else {
      links.push(entry);
    }
  }

  return { links, images };
}

export const rehypeReactSettings = {
  createElement,
  Fragment,
  components: {
    ticker: Ticker,
    hashtag: Hashtag,
    mention: Mention,
    post: PostPreview,
    a: Anchor,
    youtube: YoutubeEmbed,
    spotify: SpotifyEmbed,
    imagel: Images,
    img: Images,
    thread: ReThread,
    iframe: ExternalFrame,
    p: CustomParagraph
  }
};

export function replaceDivAndCenterTagsWithAttributes(inputText: string) {
  // Define a regular expression pattern to find the specified patterns
  const pattern = /<(div|center)([^>]*)>(.*?)<\/(div|center)>/gs;
  const italicRegex = /(\*{1,2,3})([^*]+)(\*{1,2,3})/g;

  let replacedText = inputText;

  replacedText = replacedText?.replaceAll(italicRegex, match => {
    const splittedByLines = match.split("\n");
    if (match === "* *" || match === "* **") return match;
    if (
      match?.startsWith("**") &&
      match?.endsWith("**") &&
      !splittedByLines?.includes("") &&
      !splittedByLines?.includes(" ")
    ) {
      return `<b>${match.replaceAll("**", "").trim()}</b> `;
    }

    if (splittedByLines.length === 1) {
      return `<em>${match.replaceAll("*", "").trim()}</em> `;
    }

    if (
      splittedByLines?.includes("") ||
      splittedByLines?.includes(" ") ||
      splittedByLines?.at(-1) === "*" ||
      match?.startsWith("**")
    ) {
      return match;
    } else {
      return `<em>${match.replaceAll("*", "").trim()}</em> `;
    }
  });

  // Use the replace method with a callback function to perform the replacement
  replacedText = replacedText?.replaceAll(
    pattern,
    (match, tag, attributes, content) => {
      const openingTag = `<${tag}${attributes}>`;
      const closingTag = `</${tag}>`;

      return `${openingTag}\n\n${content}\n\n${closingTag}\n`;
    }
  );

  replacedText = replacedText
    ?.replaceAll("</center>", "\n</center>\n\n")
    .replaceAll(/<div((?!\s*style\s*=\s*"[^"]*:[^"]*").)*<\/div>/g, "");
  replacedText = replacedText?.replaceAll("<center>", "\n<center>\n\n");
  replacedText = replacedText?.replaceAll("<sub>", "\n<sub>\n");
  replacedText = replacedText?.replaceAll("</sub>", "\n</sub>\n");

  replacedText = replacedText?.replace(
    /<[^>]*\style\s*=\s*"[^"]*"[^>]*>/g,
    function (match) {
      if (match.includes(":")) {
        // If the style attribute contains a colon, keep it as is
        return match;
      } else {
        // If the style attribute doesn't contain a colon, remove it
        return match.replace(/\s*style\s*=\s*"[^"]*"/, "");
      }
    }
  );

  replacedText = replacedText?.replaceAll("\n", " \n");

  return replacedText;
}
export function markdownToHtmlHeaders(markdown) {
  // This function replaces Markdown headers (#, ##, ###, etc.) with HTML headers (<h1>, <h2>, <h3>, etc.)
  return markdown.replace(
    /^(#{1,6}\s*[\S]+)/g,
    (match, newline, hashes, text) => {
      const level = hashes.length; // Calculate the header level based on the number of '#' characters
      return `${newline}<h${level}>${text?.trim()}</h${level}>`; // Convert to HTML header
    }
  );
}

export function threadMarkdownProcessor() {
  // console.log(defaultSchema.ancestors);
  return unified()
    .use(remarkParse)
    .use(leomark)
    .use(remarkBreaks)
    .use(remarkRehype, {
      allowDangerousHtml: true,
      unknownHandler: leomarkHandler
    })
    .use(rehypeStringify, { allowDangerousHtml: true })
    .use(rehypeRaw)
    .use(rehypeSanitize, {
      tagNames: [
        ...defaultSchema.tagNames,
        "center",
        "a",
        "p",
        "span",
        "mention",
        "post",
        "hashtag",
        "post",
        "ticker",
        "spotify",
        "youtube",
        "imagel",
        "thread",
        "iframe"
      ],
      attributes: {
        ...defaultSchema.attributes!,
        imagel: ["src"],
        hashtag: ["hashtag"],
        mention: ["mention"],
        ticker: ["ticker"],
        post: ["url", "author", "permlink"],
        spotify: ["track"],
        youtube: ["embedURI"],
        thread: ["author", "permlink"]
      }
    })
    .use(remarkGfm)
    .use(rehypeReact, rehypeReactSettings);
}

export function useParsedBody(body: string) {
  try {
    return useMemo(() => {
      let res = threadMarkdownProcessor().processSync(
        replaceDivAndCenterTagsWithAttributes(
          `${body.replace(/\n(https?:\/\/)/g, "$1")}` as any
        )
      ).result as ReactElement;
      return res;
    }, [body]);
  } catch {
    return "";
  }
}

export function useStrippedSlicedBody(body: string, value: number) {
  return useMemo(() => {
    const parsed = unified()
      .use(remarkParse)
      .use(remarkRehype, {
        allowDangerousHtml: true
      })
      .use(stripMarkdownToText)
      .use(remarkStringify)
      .use(rehypeSanitize)
      .use(rehypeRemark)
      .processSync(htmlToMarkdown(body));

    return sliceString(String(parsed), value);
  }, [body]);
}

export function useThreadStrippedSlicedBody(body: string, value: number) {
  return useMemo(() => {
    const parsed = unified()
      .use(leomark)
      .use(remarkParse)
      .use(remarkRehype, {
        allowDangerousHtml: false,
        unknownHandler: leomarkHandler
      })
      .use(rehypeRaw)
      .use(rehypeSanitize)
      .use(remarkGfm)
      .use(rehypeStringify)
      .processSync(htmlToMarkdown(body));

    const newBody = sliceString(String(parsed), value);

    return `${newBody}<b class="text-acc">Show More</b>`;
  }, [body]);
}

export function htmlToMarkdown(input: string) {
  // Define the mapping of HTML tags to Markdown tags
  const tagMap = {
    b: "**",
    strong: "**",
    i: "_",
    em: "_",
    u: "__",
    s: "~~",
    code: "`",
    pre: "```",
    a: "[",
    img: "",
    table: "",
    tr: "",
    th: "| ",
    td: "| ",
    div: "\n\n",
    p: "\n\n",
    center: "",
    blockquote: "> ",
    ol: "",
    ul: ""
  };

  // Replace HTML tags with Markdown tags
  let output = input;
  if (output === undefined) return input;
  for (const tag in tagMap) {
    const regex = new RegExp(`<${tag}(.*?)>(.*?)<\/${tag}>`, "gi");
    const imgRegex = /<img[^>]+src\s*=\s*["']([^"']+)["'][^>]*>/;

    if (tag === "a") {
      output = output?.replace(regex, (match, attributes, content) => {
        const hrefRegex = /href=["'](.*?)["']/i;
        const hrefMatch = attributes.match(hrefRegex);
        const href = hrefMatch ? hrefMatch[1] : "";
        return `[${content}](${href})`;
      });
    } else if (tag === "img") {
      const match = output?.match(imgRegex)?.at(1);
      output = output?.replace(imgRegex, match);
    } else {
      output = output?.replace(regex, `${tagMap[tag]}$2${tagMap[tag]}`);
    }
  }

  return output;
}
