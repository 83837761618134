import { SmallAvatar8 } from "../format/SmallAvatar";

interface ThreadFirstChild {
  author: string;
}

export function ThreadFirstChild({ author }: ThreadFirstChild) {
  return (
    <div
      className="
        p-2 pt-2 pl-5 relative pm-8 flex flex-col mt-1 cursor-pointer
        border-b border-pri dark:border-pri-d
       sm:hover:bg-pri-hov sm:dark:hover:bg-pri-hov-d transition-colors"
    >
      <div
        className="w-[1px]
          sm:left-[39.5px] md:left-[39.5px] left-[30.5px] pc:left-[39.5px]
          bottom-8
          -z-10 h-10 absolute bg-zinc-300 dark:bg-zinc-700 rounded-full "
      />
      <div className="flex flex-row">
        <SmallAvatar8 author={author} />
        <span className="pl-5 self-center text-sm text-acc">
          Show this thread
        </span>
      </div>
    </div>
  );
}
