import { useMemo } from "react";

interface YoutubeEmbed {
  embedURI: string;
}

export const YoutubeEmbed = ({ embedURI }: YoutubeEmbed) => {
  return  (
      <span className="relative w-full h-0 pb-[56.25%] rounded-xl overflow-hidden">
        <iframe
          loading="lazy"
          width="100%"
          height="560"
          src={`https://www.youtube.com/embed/${embedURI}`}
          frameBorder="0"
          className="absolute w-full max-w-full h-full overflow-hidden flex justify-center"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </span>
    )
};
