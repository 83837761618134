import { useContext, useState } from "react";
import { ClientOnly } from "remix-utils/client-only";
import { isSSR } from "~/utils/ssr";
import { ImageDimensionsContext } from "../threads/ThreadView";

interface ImageProps {
  src: string;
  alt?: string;
}

export default function Images({ src, alt }: ImageProps) {
  const isGif = src?.endsWith?.(".gif") || false;
  const isImgur = src?.startsWith("https://i.imgur.com") || false;

  const isMobile = isSSR()
    ? false
    : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      );

  let _src =
    isGif || isImgur ? src : `https://wsrv.nl/?url=${src}&q=75&output=webp`;
  let blurryLoader =
    isGif || isImgur
      ? ""
      : `https://wsrv.nl/?url=${src}&w=4&h=4&q=1&output=webp`;

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = event => {
    setImageLoaded(true);
  };

  const ImageDimensions = useContext(ImageDimensionsContext);

  const placeholderStyle = {
    maxHeight: "60vh",
    alignSelf: "center",
    // margin: "auto",
    height: "40vh",
    // width: "100%", // Default width
    // borderRadius: "3%",
    backgroundColor: "gray",
    objectFit: "cover"
  };

  const imageStyle = {
    maxHeight: isMobile ? "fit-content" : "60vh",
    maxWidth: "100%",
    alignSelf: "center",
    // margin: "auto",
    //height: (ImageDimensions[_src] && !isMobile) ? ImageDimensions[_src].height : "fit",
    // borderRadius: "3%",
    backgroundColor: "inherit",
    objectFit: isMobile ? "contain" : "cover",
    display: "block"
  };

  const [retries, setRetries] = useState(0);

  return (
    <ClientOnly>
      {() => (
        <span className="flex py-1.5 w-fit">
          <span
            style={{
              height:
                ImageDimensions[_src] && !isMobile
                  ? `${ImageDimensions[_src].height}px !important`
                  : "fit",
              maxHeight: isMobile ? "fit-content" : "60vh"
            }}
            className="flex flex-col not-prose w-fit h-fit bg-repeat  border border-pri dark:border-pri-d rounded-xl overflow-hidden"
          >
            <ClientOnly>
              {() => (
                <img
                  src={_src}
                  alt={alt ?? "Image from thread"}
                  title={alt ?? "Image from thread"}
                  className={`${imageLoaded ? "" : "pulsate"} z-1`}
                  style={imageStyle}
                  height={
                    ImageDimensions[_src] && !isMobile
                      ? ImageDimensions[_src].height
                      : "fit"
                  }
                  onLoad={handleImageLoad}
                  onError={e => {
                    if (retries === 1) {
                      e.target.src = src;
                      setRetries(2);
                    }
                    if (retries === 0) {
                      e.target.src = `https://images.hive.blog/0x0/${src}`;
                      setRetries(1);
                    }
                  }}
                />
              )}
            </ClientOnly>
          </span>
        </span>
      )}
    </ClientOnly>
  );
}
