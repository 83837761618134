import classNames from "classnames";
import type { HiveContent } from "~/utils/hive";

interface AccountNameProps {
  author: HiveContent["author"];
  className?: string;
}

export default function AccountName({ author, className }: AccountNameProps) {
  return (
    <span
      itemProp="identifier"
      className={classNames(
        "text-xs w-fit text-gray-600 dark:text-gray-500",
        className
      )}
    >
      @{author}
    </span>
  );
}
