import { lazy, memo, Suspense } from "react";
import MarkdownEditor from "~/components/MarkdownEditor";
//const MarkdownEditor = lazy(() => import("~/components/MarkdownEditor"));

export enum MarkdownEditorTypes {
  Post,
  Thread,
  Comment
}

interface ThreadsEditorProps {
  mainThreadEditor?: boolean;
  handlePost: (body: string) => Promise<void>;
}

const ThreadsEditor = memo(
  ({ mainThreadEditor, handlePost }: ThreadsEditorProps) => {
    return (
      <div className="flex flex-col flex-1">
        <Suspense fallback={<MarkdownEditorPlaceholder />}>
          <MarkdownEditor
            type={MarkdownEditorTypes.Thread}
            mainThreadEditor={mainThreadEditor}
            handlePost={handlePost}
          />
        </Suspense>
      </div>
    );
  }
);

ThreadsEditor.displayName = "ThreadsEditor";
export default ThreadsEditor;

function MarkdownEditorPlaceholder() {
  return (
    <div className="flex flex-col w-full h-[133px] border-b border-pri dark:border-pri-d animate-pulse">
      <div className="flex items-center w-full h-20 gap-x-4 p-4">
        <span className="flex shrink-0 w-12 h-12 rounded-full bg-gray-200 dark:bg-zinc-800" />
        <span className="flex w-36 h-6 rounded bg-gray-200 dark:bg-zinc-800" />
      </div>

      <div className="flex items-center justify-between w-full h-[52px] p-2 gap-x-3">
        <div className="flex flex-1 items-center shrink-0">
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(key => (
            <div
              key={key}
              className="flex items-center justify-center shrink-0 w-9 h-9 overflow-hidden"
            >
              <span
                key={key}
                className="flex w-7 h-7 bg-gray-200 dark:bg-zinc-800 rounded-full"
              />
            </div>
          ))}
        </div>

        <span className="flex w-[88px] h-[35px] rounded-full bg-gray-200 dark:bg-zinc-800" />
      </div>
    </div>
  );
}
