import { HTMLProps } from "react";

const CustomParagraph = (props: HTMLProps<HTMLParagraphElement>) => {
  return (
    <p
      className="break-words"
      style={{
        wordBreak: "normal",
        whiteSpace: "normal"
      }}
      {...props}
    >
      {" "}
      {props.children}{" "}
    </p>
  );
};

export default CustomParagraph;
