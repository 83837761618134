import type { Dispatch, SetStateAction } from "react";
import { KeyboardEvent, useState } from "react";
import { useAppStore } from "~/store";
import { toast } from "react-toastify";
import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import PublishFormInput from "~/components/publish/PublishEditor/PublishFormInput";
import { broadcastAll } from "~/utils/transactions";
import { hivesigner } from "~/utils/hivesigner";
import { KeychainKeyType } from "~/utils/keychain";

interface AddBeneficiariesModalProps {
  authority?: string;
  visibility: boolean;
  setVisibility: Dispatch<SetStateAction<boolean>>;
}

export default function PostingAuthorityModal({
  authority,
  visibility,
  setVisibility
}: AddBeneficiariesModalProps) {
  if (!visibility) return null;
  const [{ active, posting, memo_key, json_metadata, name }, setAuthorityTrue] =
    useAppStore(store => [
      store.account.activeAccount,
      store.markdown.setAuthorityTrue
    ]);

  const isDarkMode = useAppStore(store => store.settings.dark);

  const [username, setUsername] = useState("");
  const [reward, setReward] = useState("");

  const handleGrant = async () => {
    if (window.hive_keychain) {
      new Promise((resolve, reject) => {
        (window as any).hive_keychain.requestAddAccountAuthority(
          name,
          authority ? authority : "leofinance",
          "Posting",
          1,
          result => setVisibility(false)
        );
      });
      setAuthorityTrue();
    } else {
      window.open(
        "https://hivesigner.com/authorize/leofinance?redirect_uri=https://inleo.io",
        "popup",
        "width=600,height=600"
      );
      setAuthorityTrue();

      setTimeout(() => setVisibility(false), 500);
    }

    /* 
    const operation = [
      "account_update",
      {
        posting: {
          ...posting,
          account_auths: [...posting.account_auths, ["leofinance5", 1]]
        },
        json_metadata: json_metadata,
        account: name
      }
    ];

    await broadcastAll({
      author: name,
      hivesignerClient: hivesigner,
      type: KeychainKeyType.Active,
      operations: [operation]
    }).catch(async error => {
      console.log({ POSTING_ERROR: error });
    });*/
  };

  return (
    <FloatingHTMLOverlay
      onClick={ev => ev.stopPropagation()}
      className="bg-overlay z-[1000] flex justify-center py-[10vh]"
      lockScroll
    >
      <div className="relative py-6 px-6 flex flex-col gap-y-6 rounded-xl bg-pri dark:bg-pri-d border border-pri dark:border-pri-d w-10/12 h-fit pc:w-4/12 pc:max-h-fit md:w-7/12 sm:w-9/12 xs:w-full">
        <header className="flex items-center justify-between pb-5">
          <div className="flex flex-1 flex-col pr-10 gap-2">
            <strong className="text-lg">Grant Authority</strong>
          </div>
          <div
            onClick={() => setVisibility(false)}
            className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </header>

        <div className="flex flex-col gap-10">
          In order to use this feature, you need to grant @
          {authority || "leofinance"} posting authority
        </div>

        <footer className="flex justify-end gap-x-2">
          <button
            className="py-2.5 px-5 rounded-lg border border-pri dark:border-pri-d text-sm font-medium hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors duration-150"
            onClick={() => setVisibility(false)}
          >
            Cancel
          </button>
          <button
            className="py-2 px-8 rounded-lg bg-acc text-sm text-pri font-semibold hover:bg-acc transition-colors"
            onClick={() => handleGrant(false)}
          >
            Grant Authority
          </button>
        </footer>
      </div>
    </FloatingHTMLOverlay>
  );
}
