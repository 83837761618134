import * as React from "react";
import { useAppStore } from "~/store";
import classNames from "classnames";

interface FormatButtonProps {
  children: React.ReactNode;
  className?: string;
  label?: string;
  handleClick: React.MouseEventHandler;
  requirePremium?: boolean;
}

function FormatButton({
  className,
  label,
  children,
  handleClick,
  requirePremium
}: FormatButtonProps) {
  const premiumState = useAppStore(store => store.account.premium);

  return (
    <button
      type="button"
      title={label}
      aria-label={label}
      className={classNames(
        className,
        "flex items-center justify-center w-10 h-10 sm:w-9 sm:h-9 rounded-full bg-pri dark:bg-pri-d hover:bg-acc/10 hover:dark:bg-acc/10",
        {
          "opacity-50 cursor-not-allowed":
            requirePremium && !premiumState.is_premium
        }
      )}
      onClick={event => {
        if (requirePremium) {
          if (premiumState.is_premium) {
            handleClick(event);
          }

          return;
        }

        handleClick(event);
      }}
      onMouseDown={ev => ev.preventDefault()}
    >
      {children}
    </button>
  );
}


export default FormatButton;