import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  OperationCategoryUnion,
  OperationTypeUnion
} from "~/store/loading-indicator";
import { useAppStore } from "~/store";
import { useEffect } from "react";

type Props = {
  operationType: OperationTypeUnion;
  operationCategory: OperationCategoryUnion;
};

const minuteValues = {
  edit: {
    post: 10,
    community: 5,
    thread: 5,
    profile: 5
  }
};

export default function LoadingIndicatorBadge({
  operationType,
  operationCategory
}: Props) {
  const minutes = minuteValues[operationType][operationCategory];

  const loadingIndicator = useAppStore(state => state.loadingIndicator);

  useEffect(() => {
    setTimeout(() => {
      loadingIndicator.setLoadingIndicators(
        loadingIndicator.items.filter(
          item =>
            item.type !== operationType && item.category !== operationCategory
        )
      );
    }, minutes * 60 * 1000);
  }, [operationType, operationCategory]);

  return (
    <div className="flex gap-4 items-center border-none text-white text-[0.75rem] rounded-lg px-2 py-1 bg-gray-600">
      <FontAwesomeIcon size="sm" icon={faHourglass} />
      <p>Changes may take up to {minutes} minutes to take effect</p>
      <FontAwesomeIcon size="sm" icon={faTimesCircle} />
    </div>
  );
}
