import { Link } from "@remix-run/react";

interface MentionProps {
  mention: string;
}

export default function Mention({ mention }: MentionProps) {
  if (mention.startsWith("3speak.")) {
    const [_, author, permlink] = mention.split(".");
    return (
      <iframe
        width="560"
        height="315"
        src={`https://3speak.tv/embed?v=${author}/${permlink}`}
        frameBorder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  }

  return (
    <Link
      prefetch="intent"
      to={`/profile/${mention}`}
      className="text-acc dark:text-acc-d"
      onClick={ev => ev.stopPropagation()}
    >
      @{mention}
    </Link>
  );
}
