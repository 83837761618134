import { PollOptions } from "~/components/format/Polls";
import { useSlate } from "slate-react";
import { useEffect, useMemo, useState } from "react";
import * as React from "react";
// @ts-ignore
import debounce from "lodash.debounce";
import { Descendant, Node } from "slate";
import {
  replaceDivAndCenterTagsWithAttributes,
  threadMarkdownProcessor
} from "~/utils/markdown";
import { ThreadText } from "~/components/threads/ThreadViewHelpers";
import { PollsPreview } from "~/components/format/PollsPreview";

interface PreviewProps {
  pollOptions: PollOptions;
  isPollCreated: boolean;
  editPoll: () => void;
  removePoll: () => void;
}

export function Preview({
  pollOptions,
  isPollCreated,
  editPoll,
  removePoll
}: PreviewProps) {
  const editor = useSlate();
  const [parsed, setParsed] = useState<null | React.ReactNode>(null);

  const setDebounced = useMemo(
    () =>
      debounce(
        (children: Descendant[]) => {
          let body = `⠀${children.map(n => Node.string(n))?.join(`\n`)}`;
          let result = threadMarkdownProcessor().processSync(
            replaceDivAndCenterTagsWithAttributes(
              `${body
                .replaceAll("⠀", "")
                .replace(/\n(https?:\/\/)/g, "$1")}` as any
            )
          ).result;

          if ((result as any)?.props?.children === undefined) {
            setParsed(null);
          } else {
            setParsed(result);
          }
        },
        250,
        { leading: true, maxWait: 1000 }
      ),
    [setParsed]
  );

  useEffect(() => {
    setDebounced(editor.children);
  }, [editor?.children, setDebounced]);

  if (parsed === null && !isPollCreated) {
    return null;
  }

  const isPollsVisible =
    isPollCreated &&
    Object.keys(pollOptions).length > 0 &&
    !Object.values(pollOptions).every(val => !val);
  return (
    <div className="flex-1 shrink-0 p-2">
      {parsed && <ThreadText>{parsed}</ThreadText>}
      {isPollsVisible && (
        <PollsPreview
          poll={pollOptions}
          editPoll={editPoll}
          removePoll={removePoll}
        />
      )}
    </div>
  );
}
