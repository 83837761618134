import React, { useMemo, useState } from "react";
import type { PossibleScotContent, ScotContent } from "~/utils/scot";
import { ScotFetchState } from "~/hooks/contentHooks";
import {
  flip,
  offset,
  safePolygon,
  useFloating,
  useHover,
  useInteractions
} from "@floating-ui/react";
import classNames from "classnames";
import type { HiveContent } from "~/utils/hive";
import { TimeSincePublish } from "./TimeSincePublish";
import { SmallAvatar6 } from "./format/SmallAvatar";
import currencySymbolMap from "currency-symbol-map";
import { useAppStore } from "~/store";
import parseAsset from "~/utils/asset";
import { isSSR } from "~/utils/ssr";

interface PayoutProps {
  state: ScotFetchState;
  scotContent: PossibleScotContent;
  className?: string;
  hiveContent: HiveContent;
  fetchScottContent?: () => void;
}

const Payout = ({
  state,
  scotContent,
  className,
  hiveContent,
  fetchScottContent = () => null
}: PayoutProps) => {
  const [global, median] = useAppStore(store => [
    store.wallet.dynamicGlobalProperties,
    store.wallet.median
  ]);

  if (!hiveContent || isSSR() || state == ScotFetchState.Initial) {
    return <PayoutLoader />;
  }

  if (state == ScotFetchState.Failed) {
    fetchScottContent();
  }

  const beneficiaries = hiveContent.beneficiaries || [];
  const pendingPayout = parseAsset(hiveContent.pending_payout_value).amount;
  const promotedPayout = parseAsset(hiveContent.promoted).amount;
  const authorPayout = parseAsset(hiveContent.author_payout_value).amount || 0;
  const curatorPayout = parseAsset(hiveContent.curator_payout_value).amount;
  const maxPayout = parseAsset(hiveContent.max_accepted_payout).amount;
  const fullPower = hiveContent.percent_hbd === 0;
  const payout_at = hiveContent.payout_at || hiveContent.cashout_time;

  const totalPayout = pendingPayout + authorPayout + curatorPayout;
  const payoutLimitHit = totalPayout >= maxPayout;

  const HBD_PRINT_RATE_MAX = 10000;
  const percentHiveDollars = hiveContent.percent_hbd / 20000;
  const pendingPayoutHbd = pendingPayout * percentHiveDollars;
  const pricePerHive = median.base / median.quote;
  const pendingPayoutHp = (pendingPayout - pendingPayoutHbd) / pricePerHive;
  const pendingPayoutPrintedHbd =
    pendingPayoutHbd * (global?.hbd_print_rate / HBD_PRINT_RATE_MAX);
  const pendingPayoutPrintedHive =
    (pendingPayoutHbd - pendingPayoutPrintedHbd) / pricePerHive;

  const check = hiveContent.max_accepted_payout;
  let isPayoutDeclined = false,
    shownPayout = 0;

  if (check) {
    isPayoutDeclined = parseAsset(hiveContent.max_accepted_payout).amount === 0;
    shownPayout = payoutLimitHit && maxPayout > 0 ? maxPayout : totalPayout;
  }

  const props: Omit<FetchedPayoutProps, "scotContent"> = {
    beneficiaries,
    pendingPayout,
    promotedPayout,
    authorPayout,
    curatorPayout,
    pendingPayoutHp,
    pendingPayoutPrintedHbd,
    pendingPayoutPrintedHive,
    shownPayout,
    isPayoutDeclined,
    payout_at,
    fullPower,
    className
  };

  return (
    <FetchedPayout
      scotContent={
        scotContent ??
        ({ pending_token: 0, total_payout_value: 0 } as ScotContent)
      }
      {...props}
    />
  );
};

export default Payout;

interface FetchedPayoutProps {
  scotContent: ScotContent;
  beneficiaries: { account: string; weight: number }[];
  pendingPayout: number;
  promotedPayout: number;
  authorPayout: number;
  curatorPayout: number;
  pendingPayoutHp: number;
  pendingPayoutPrintedHbd: number;
  pendingPayoutPrintedHive: number;
  shownPayout: number;
  payout_at: string;
  isPayoutDeclined: boolean;
  fullPower: boolean;
  className?: string;
}

function FetchedPayout({
  scotContent,
  beneficiaries,
  pendingPayout,
  promotedPayout,
  authorPayout,
  curatorPayout,
  pendingPayoutHp,
  pendingPayoutPrintedHbd,
  pendingPayoutPrintedHive,
  shownPayout,
  isPayoutDeclined,
  payout_at,
  fullPower,
  className
}: FetchedPayoutProps) {
  const { denom, tokenPrices } = useAppStore(store => store.wallet);

  const [open, setOpen] = useState(false);
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(10), flip()],
    placement: "bottom-end"
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { handleClose: safePolygon(), delay: 100 })
  ]);

  const payoutValue = useMemo(
    () =>
      (scotContent.pending_token || scotContent.total_payout_value || 0) /
      (denom || 0),
    [scotContent, denom]
  );

  const leoPayout = useMemo(
    () =>
      !tokenPrices
        ? 0
        : (payoutValue || 0) * (tokenPrices["wrapped-leo"]?.usd || 0),
    [payoutValue, tokenPrices]
  );

  let breakdownPayout: string[] = [];

  if (pendingPayout > 0) {
    if (pendingPayoutPrintedHbd > 0) {
      breakdownPayout.push(
        `${LEOFormatter.format(pendingPayoutPrintedHbd)} HBD`
      );
    }

    if (pendingPayoutPrintedHive > 0) {
      breakdownPayout.push(
        `${LEOFormatter.format(pendingPayoutPrintedHive)} HIVE`
      );
    }

    if (pendingPayoutHp > 0) {
      breakdownPayout.push(`${LEOFormatter.format(pendingPayoutHp)} HP`);
    }
  }

  const displayPayout = shownPayout + (leoPayout || 0);
  const isBurnedAll = beneficiaries.some(
    ({ account: _acc, weight }) => _acc == "null" && weight / 100 == 100
  );
  return (
    <React.Fragment>
      <div
        ref={reference}
        onClick={ev => ev.stopPropagation()}
        {...getReferenceProps()}
        className={classNames(
          "flex items-center justify-center text-xs leading-normal font-semibold h-8 px-2.5 rounded-lg bg-green-500/[.15] text-green-500",
          className
        )}
      >
        {isBurnedAll && "🔥"}
        <DefaultCurrencyComponent
          value={displayPayout}
          className="flex flex-row gap-1 leading-normal"
        />
      </div>

      {open && (
        <div
          className="w-max min-w-[180px] py-4 pb-1 px-4 gap-y-3 leading-snug rounded-lg text-sm bg-pri dark:bg-pri-d border border-pri dark:border-pri-d flex flex-col transition-transform duration-150 z-[1001] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 translate-x-0 drop-shadow-md shadow-[0_0_12px_3px_rgb(255_255_255_/_12%)]"
          ref={floating}
          style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
          onClick={ev => ev.stopPropagation()}
          data-side="bottom"
          data-state={open ? "open" : "closed"}
          {...getFloatingProps()}
        >
          <div className="flex flex-1 flex-col gap-y-1">
            <strong className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
              {isBurnedAll ? "Burned Rewards" : "Pending Payout"}
            </strong>
            <span className="text-sm">
              ${USDFormatter.format(shownPayout)} + $
              {USDFormatter.format(leoPayout)}
            </span>
          </div>

          {promotedPayout > 0 && (
            <div className="flex flex-1 flex-col gap-y-1">
              <strong className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
                Promoted Payout
              </strong>
              <span className="text-sm">
                ${USDFormatter.format(promotedPayout)}
              </span>
            </div>
          )}

          {authorPayout > 0 && (
            <div className="flex flex-1 flex-col gap-y-1">
              <strong className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
                Author Payout
              </strong>
              <span className="text-sm">
                ${USDFormatter.format(promotedPayout)}
              </span>
            </div>
          )}

          {curatorPayout > 0 && (
            <div className="flex flex-1 flex-col gap-y-1">
              <strong className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
                Curator Payout
              </strong>
              <span className="text-sm">
                ${USDFormatter.format(promotedPayout)}
              </span>
            </div>
          )}

          {breakdownPayout.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
                Breakdown
              </span>
              <span className="text-sm">
                <span>{LEOFormatter.format(payoutValue)} LEO</span>
                <br />
                {breakdownPayout.map((x, i) => (
                  <React.Fragment key={i}>
                    {x} <br />
                  </React.Fragment>
                ))}
              </span>
            </div>
          )}

          <div className="flex flex-1 flex-wrap items-center gap-1">
            <strong className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
              Payout at
            </strong>
            <span className="text-sm">
              <TimeSincePublish
                publishTime={payout_at}
                className="text-sm font-medium text-pri dark:text-pri-d"
              />
            </span>
          </div>

          <div className="flex flex-col gap-y-1">
            <span className="text-sm font-medium text-pri/50 dark:text-pri-d/50">
              {beneficiaries?.length === 0 && "No"} Beneficiaries{" "}
              {beneficiaries?.length > 0 && (
                <span className="text-pri dark:text-pri-d">
                  ({beneficiaries?.length || 0})
                </span>
              )}
            </span>

            <div className="flex flex-col gap-x-3">
              {beneficiaries.map(({ account, weight }) => (
                <div
                  key={account}
                  className="flex flex-row items-center gap-x-1.5"
                >
                  <SmallAvatar6 author={account} className="shrink-0" />
                  <span className="text-sm text-left">{account}</span>
                  <span className="text-xs text-pri/50 dark:text-pri-d/50 font-bold">
                    {weight / 100}%
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

function PayoutLoader() {
  return (
    <span className="flex w-[70px] h-[36px] bg-green-500/10 rounded-lg animate-pulse" />
  );
}

const LEOFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 3
});

const USDFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

interface DefaultCurrencyOutput {
  value: number;
  className: string;
}

export function DefaultCurrencyComponent({
  value,
  className
}: DefaultCurrencyOutput) {
  const [settings, tokenPrices] = useAppStore(store => [
    store.settings,
    store.wallet.tokenPrices
  ]);

  const defaultCurrency = settings.default_currency || "USD";

  return (
    <div className={className}>
      +
      {(tokenPrices
        ? value * tokenPrices?.["currencies"]?.[defaultCurrency]
        : 0
      ).toFixed(2)}
      <div>{currencySymbolMap(defaultCurrency)}</div>
    </div>
  );
}

export function DefaultCurrencyOutput(value: number) {
  const [settings, tokenPrices] = useAppStore(store => [
    store.settings,
    store.wallet.tokenPrices
  ]);

  const defaultCurrency = settings.default_currency || "USD";
  return `${(tokenPrices
    ? value * tokenPrices?.["currencies"]?.[defaultCurrency]
    : 0
  ).toFixed(2)} ${currencySymbolMap(defaultCurrency)}`;
}
