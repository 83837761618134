import { FloatingPortal } from "@floating-ui/react";
import {
  faX,
  faMinus,
  faAdd,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyMotion, m } from "framer-motion";
const loadFeatures = () =>
  import("~/components/framermotion/features").then(res => res.default);
import {
  Dispatch,
  Fragment,
  lazy,
  memo,
  SetStateAction,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from "react";
import { toast } from "react-toastify";
import { AuthorPerm } from "~/utils/hive";
import { pollVote } from "~/utils/transactions";
import Popover from "../Popover";
import { useAppStore } from "~/store";
import { Link } from "@remix-run/react";
import classNames from "classnames";
import { SmallAvatar6 } from "./SmallAvatar";

const ProfileLink = lazy(() => import("~/components/ProfileLink"));

export interface PollOptions {
  [key: number]: string;
}

export interface PollOptionView {
  count: number;
  pollOptions: PollOptions;
  authorPerm?: AuthorPerm;
  author?: string; //active user
  setPollVoteState: Dispatch<SetStateAction<number>>;
}

export interface PollView {
  pollOptions: PollOptions;
  authorPerm?: AuthorPerm | false;
  author?: string | false;
  pollContent?: any;
  setPollVoteState?: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
}

export function PollOptionView({
  count,
  pollOptions,
  authorPerm,
  author,
  setPollVoteState
}: PollOptionView) {
  const optionHandler = (event: MouseEvent) => {
    if (!authorPerm || !author) return;
    //alert("asda")
    event.stopPropagation();
    pollVote({
      option: count,
      author,
      authorPerm
    }).then(() => setPollVoteState(1));
  };

  if (pollOptions[count] === "") return null;
  // <div
  //   onClick={optionHandler}
  //   className="border border-acc text-acc rounded-full
  //   cursor-pointer select-none bg-none w-full p-1 font-semibold text-center text-md
  //   hover:bg-acc/[0.2]
  // "
  // >
  //   {pollOptions[count]}
  // </div>
  return (
    <div
      onClick={optionHandler}
      className="flex flex-1 justify-center items-center border border-pri dark:border-pri-d rounded-xl 
        cursor-pointer select-none bg-none text-sm py-2.5 px-5 font-medium text-center
        hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075]"
    >
      {pollOptions[count]}
    </div>
  );
}

export interface PollOptions {
  [key: string]: string;
}

interface PollPortal {
  pollsOpen: boolean;
  setPollsOpen: Dispatch<SetStateAction<boolean>>;
  setPollOptions: Dispatch<SetStateAction<PollOptions>>;
  pollOptions: PollOptions;
  isPollCreated: boolean;
  handlePoll: (poll: PollOptions) => void;
}

export const PollPortal = function PollPortal({
  pollsOpen,
  setPollsOpen,
  setPollOptions,
  pollOptions,
  isPollCreated,
  handlePoll
}: PollPortal) {
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const isDarkMode = useAppStore(store => store.settings.dark);

  const changePollOption = (index: number, text: string) => setPollOptions( { ...pollOptions, ...{ [index]: text } });

  const normalizeOptions = useCallback((options: PollOptions): PollOptions => {
    const normalizedOptions = Object.entries(options).filter(
      ([key, value]) => value
    );
    const sequentialKeysObject: PollOptions = {};

    let index = 1;
    for (const key in normalizedOptions) {
      sequentialKeysObject[index] = normalizedOptions[key][1];
      index++;
    }

    return sequentialKeysObject;
  }, []);

  const handleDiscardPolls = useCallback(() => {
    const normalizedPollOptions = normalizeOptions(pollOptions);
    setPollOptions(normalizedPollOptions);

    if (isPollCreated) {
      setPollsOpen(false);
      return;
    }

    setPollOptions({
      0: "",
      1: ""
    });
    setPollsOpen(false);
  }, [pollOptions, isPollCreated, normalizeOptions, setPollOptions, setPollsOpen]);

  const handleAddPoll = useCallback(() => {
    if (pollsOpen && Object.values(pollOptions).every(value => !value)) {
      toast("You need to add at least 1 option to create a poll.", {
        type: "error",
        autoClose: 3000,
        theme: isDarkMode ? "dark" : "light"
      });

      return;
    }
    const normalizedPollOptions = normalizeOptions(pollOptions);
    handlePoll(normalizedPollOptions);
    setPollsOpen(false);
  }, [
    pollsOpen,
    pollOptions,
    isDarkMode,
    setPollsOpen,
    handlePoll,
    normalizeOptions
  ]);

  const isDisabledAddPollOption = useMemo(
    () => Object.keys(pollOptions).length >= 4,
    [pollOptions]
  );

  const removePollOption = useCallback((index: string) => {
    const modified = Object.entries(pollOptions).filter(([key]) => key !== index);
    const updated = Object.fromEntries(modified);
    setPollOptions(updated);
  }, [pollOptions, setPollOptions]);



  const addPollOption = useCallback(() => {
    if(isDisabledAddPollOption) return;
    const clone = Object.entries(pollOptions);
    clone.push([String(Math.max(...clone.map(obj => +obj[0])) + 1), ""]);
    const modified = Object.fromEntries(clone);
    
    setPollOptions(modified);
  }, [isDisabledAddPollOption, setPollOptions, pollOptions]);


  useEffect(() => {
    if (!pollsOpen) return;
    const handleEnterPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleAddPoll();
      }
    };

    window.addEventListener("keydown", handleEnterPress);

    return () => window.removeEventListener("keydown", handleEnterPress);
  }, [pollsOpen, handleAddPoll]);

 
  return (
    <FloatingPortal>
      <LazyMotion features={loadFeatures}>
        {pollsOpen && (
          <m.div
            initial={{ opacity: 0, scale: 0.97 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.97 }}
            className="fixed inset-0 flex justify-center items-center bg-black/30 z-[10001]"
          >
            <div
              ref={modalInnerRef}
              className="flex flex-col min-w-[360px] sm:min-w-[520px] py-5 px-6 gap-y-9 rounded-xl drop-shadow-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)]"
            >
              <header className="flex flex-1 items-center justify-between gap-x-8">
                <h1 className="text-2xl font-bold">Create a Poll</h1>
                <button
                  type="button"
                  className="flex items-center justify-center w-8 h-8 rounded-full border border-pri/50 dark:border-pri-d/50 text-pri/80 dark:text-pri-d/80 hover:border-pri dark:hover-border-pri-d hover:text-pri dark:hover:text-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150"
                  onClick={() => handleDiscardPolls()}
                >
                  <FontAwesomeIcon icon={faX} size="xs" />
                </button>
              </header>

              <main className="flex flex-1 flex-col w-full gap-y-6">
                <div className="flex flex-1 flex-col gap-y-3">
                  {Object.entries(pollOptions).map(([_, value], index) => (
                    <div
                      className="flex flex-1 items-center gap-x-4"
                      key={index}
                    >
                      <input
                        type="text"
                        className="flex flex-1 w-full py-3 px-4 bg-transparent rounded-xl border border-pri dark:border-pri-d outline outline-2 outline-offset-2 outline-transparent focus:outline-pri dark:focus:outline-pri-d transition-all duration-150"
                        placeholder={`Choice ${index + 1}`}
                        value={value}
                        onChange={event =>
                          changePollOption(+_, event.target.value)
                        }
                        maxLength={25}
                      />

                      {index !== 0 && (
                        <Fragment>
                          <button
                            id={`choice-${index}`}
                            type="button"
                            aria-label="Remove Choice"
                            className="flex justify-center items-center w-8 h-8 rounded-full bg-red-500/10 text-red-500"
                            onClick={() => removePollOption(_)}
                            tabIndex={-1}
                          >
                            <FontAwesomeIcon
                              icon={faMinus}
                              size="sm"
                              fixedWidth
                            />
                            <span className="sr-only">Remove Choice</span>
                          </button>

                          <Popover
                            anchorId={`choice-${index}`}
                            place="top"
                            content="Remove Choice"
                          />
                        </Fragment>
                      )}

                      {index === 0 && (
                        <span tabIndex={-1} className="flex w-8 h-8" />
                      )}
                    </div>
                  ))}
                </div>

                <button
                  type="button"
                  className="flex self-end items-center gap-x-3 hover:opacity-80 disabled:hover:opacity-50 transition-opacity duration-150 disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
                  onClick={() => addPollOption()}
                  disabled={isDisabledAddPollOption}
                >
                  <span className="text-sm font-medium text-pri dark:text-pri-d">
                    Add Choice
                  </span>
                  <span
                    role="button"
                    className="flex items-center justify-center w-8 h-8 rounded-full bg-pri-d dark:bg-pri text-pri-d dark:text-pri text-sm font-medium"
                  >
                    <FontAwesomeIcon icon={faAdd} size="sm" fixedWidth />
                  </span>
                </button>

                <div className="flex items-center justify-end gap-x-1">
                  <button
                    type="button"
                    aria-label="Cancel"
                    className="flex items-center w-fit py-2.5 px-5 rounded-full border border-pri dark:border-pri-d text-sm font-medium hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors duration-150"
                    onClick={() => handleDiscardPolls()}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    aria-label="Delegate"
                    className="flex items-center justify-center w-fit min-h-[42px] min-w-[108px] py-2 px-6 rounded-full bg-pri-d dark:bg-pri text-pri-d dark:text-pri text-sm font-medium hover:opacity-80 transition-opacity duration-150"
                    onClick={() => handleAddPoll()}
                    disabled={Object.values(pollOptions).length === 0}
                  >
                    {isPollCreated ? "Save" : "Create Poll"}
                  </button>
                </div>
              </main>
            </div>
          </m.div>
        )}
      </LazyMotion>
    </FloatingPortal>
  );
};

export interface PollVotedOptionView {
  percent: number;
  optionName: string;
  isVoted: boolean;
  voters: string[];
}

export function PollVotedOptionView({
  percent,
  optionName,
  isVoted,
  voters
}: PollVotedOptionView) {
  if (!optionName) return null;

  return (
    <div className="flex items-center justify-between w-full h-[48px] relative rounded-xl border border-pri dark:border-pri-d">
      <span
        className={classNames(
          "absolute left-[2px] top-[2px] max-w-[calc(100%-4px)] h-[42px] rounded-[9px] select-none z-0",
          {
            "bg-pri-d/10 dark:bg-pri/10": !isVoted,
            "bg-acc/[.25]": isVoted
          }
        )}
        style={{ width: `${percent}%` }}
      />

      <div className="flex flex-1 items-center justify-between w-full px-4">
        <span
          className={classNames(
            "flex flex-1 items-center gap-x-1 text-sm font-medium z-10",
            {
              "text-pri/40 dark:text-pri-d/40": !isVoted,
              "text-acc font-semibold": isVoted
            }
          )}
        >
          <span>{optionName}</span>
          {isVoted && (
            <FontAwesomeIcon icon={faCheckCircle} size="sm" fixedWidth />
          )}
        </span>

        <div className="flex items-center gap-x-1">
          <div className="relative flex items-center -space-x-2">
            {voters.slice(0, 3).map((v, i) => (
              <Suspense key={i}>
                <ProfileLink accountName={v} referrer={v}>
                  <Link
                    to={`/profile/${v}`}
                    className="flex shrink-0"
                    style={{ zIndex: (i + 1) * (-i + 1) + 3 }}
                    key={i}
                    aria-label={`${v}'s profile`}
                    title={`${v}'s profile`}
                  >
                    <SmallAvatar6
                      author={v}
                      className="w-8 h-8 sm:w-6 sm:h-6"
                    />
                  </Link>
                </ProfileLink>
              </Suspense>
            ))}
          </div>
          <span
            className={classNames(
              "text-sm text-end font-medium z-10 min-w-[30px] sm:min-w-[38px] whitespace-nowrap",
              {
                "text-pri/60 dark:text-pri-d/60": !isVoted,
                "text-acc font-semibold": isVoted
              }
            )}
          >
            {percent.toFixed(0)}%
          </span>
        </div>
      </div>
    </div>
  );
}

