import classNames from "classnames";
import type { HiveContent } from "~/utils/hive";

interface ReputationProps {
  reputation: HiveContent["author_reputation"];
  className?: string;
}

export default function Reputation({ reputation, className }: ReputationProps) {
  return (
    <span
      className={classNames(
        "flex shrink-0 w-[22px] h-[22px]  items-center justify-center rounded-full border-2 border-bg dark:border-bg-d ml-1 bg-gradient-to-br from-pri-d to-pri-hov-d dark:from-pri dark:to-pri-hov py-1.5 text-xs font-semibold text-pri-d dark:text-pri",
        className
      )}
    >
      {(reputation || 25).toFixed(0)}
    </span>
  );
}
