import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useLeoPolls, HiveFetchState } from "~/hooks/contentHooks";
import { Spinner } from "./Spinner";
import { UnvotedPollView } from "./UnvotedPollView";
import { VotedPollView } from "./VotedPollView";

//@ts-ignore
export default function PollView({
  authorPerm,
  author,
  pollOptions
}): JSX.Element {
  const [pollVoteState, setPollVoteState] = useState(0);
  const [fetchState, pollContent] = useLeoPolls(
    authorPerm.permlink,
    author,
    pollVoteState
  );

  useEffect(() => {
    if (pollVoteState === 1) setTimeout(() => setPollVoteState(2), 3500);
    if (pollVoteState === 2) setTimeout(() => setPollVoteState(3), 5500);
  }, [pollVoteState]);

  const randomHint = [
    "HBD is a stablecoin that pays 20% APR if you save it in your Hive blockchain wallet",
    "The DHF stands for Decentralized Hive Fund and allows anyone to request funding to build on Hive",
    "Hive processes blocks in just 3 seconds and anyone can publish transactions for free*",
    "Every Thread you post on InLeo is published directly to the Hive blockchain",
    "When you vote on a Poll, you're casting a CUSTOM JSON transaction on a blockchain",
    "Hive uses a consensus mechanism called Delegated Proof of Stake (DPoS)",
    "Powering up the LEO token will give you more influence on inleo.io",
    "The LEO token can be wrapped to different blockchains to earn yield on DeFi platforms"
  ];

  const [placeholder] = useState(() => {
    const index = Math.floor(Math.random() * randomHint?.length!);
    return randomHint[index];
  });

  if (pollVoteState === 0) {
    if (fetchState === HiveFetchState.Initial)
      return <Spinner text="Fetching results from cache" />;
    if (pollContent) {
      //@ts-ignore
      const { account } = pollContent;
      if (!account.voted)
        return (
          <UnvotedPollView
            setPollVoteState={setPollVoteState}
            pollOptions={pollOptions}
            pollContent={pollContent}
            authorPerm={authorPerm}
            author={author}
          />
        );
    } else {
      return (
        <UnvotedPollView
          pollOptions={pollOptions}
          authorPerm={false}
          author={false}
        />
      );
    }
    if (fetchState === HiveFetchState.Fetched) {
      return (
        <VotedPollView
          pollOptions={pollOptions}
          authorPerm={authorPerm}
          author={author}
          pollContent={pollContent}
        />
      );
    }
  } else {
    return (
      <div>
        {pollVoteState === 1 && (
          <div className="relative flex justify-center items-center">
            <UnvotedPollView
              setPollVoteState={setPollVoteState}
              pollOptions={pollOptions}
              pollContent={pollContent}
              authorPerm={authorPerm}
              author={author}
              className="opacity-50 pointer-events-none"
            />

            {/* <Spinner text="Writing custom json to hive" /> */}
            <FontAwesomeIcon
              icon={faSpinner}
              size="lg"
              className="absolute animate-spin"
            />
          </div>
        )}
        {pollVoteState === 2 && (
          <div className="relative flex justify-center items-center">
            <UnvotedPollView
              setPollVoteState={setPollVoteState}
              pollOptions={pollOptions}
              pollContent={pollContent}
              authorPerm={authorPerm}
              author={author}
              className="opacity-50 pointer-events-none"
            />

            {/* <Spinner text="Writing custom json to hive" /> */}
            <FontAwesomeIcon
              icon={faSpinner}
              size="lg"
              className="absolute animate-spin"
            />
          </div>
        )}
        {pollVoteState === 3 && (
          <VotedPollView
            pollOptions={pollOptions}
            authorPerm={authorPerm}
            author={author}
            pollContent={pollContent}
          />
        )}
      </div>
    );
  }
}
