export function LoadingIndicator() {
  return (
    <div className="motion-safe:animate-pulse flex flex-col gap-3">
      <div className="grid gap-2 grid-cols-3">
        <div className="h-2 bg-gray-200 dark:bg-zinc-700 rounded col-span-1" />
        <div className="h-2 bg-gray-200 dark:bg-zinc-700 rounded col-span-2" />
      </div>
      <div className="h-2 bg-gray-200 dark:bg-zinc-700 rounded" />
      <div className="grid gap-5 grid-cols-3">
        <div className="h-2 bg-gray-200 dark:bg-zinc-700 rounded col-span-2" />
      </div>
    </div>
  );
}
