import * as React from "react";
import type { PossibleThreadContent, ThreadIndexed } from "~/utils/thread";
import { SmallAvatar12 } from "~/components/format/SmallAvatar";
import AccountName from "~/components/format/AccountName";
import type { ThreadContent } from "~/utils/thread";
import { useMemo } from "react";
import DisplayName from "~/components/format/DisplayName";
import { ThreadContainer, ThreadBody, ThreadHeader } from "./ThreadViewHelpers";
import { LoadingThread } from "./LoadingThreads";
import { FullThread } from "./FullThread";
import { useTraceUpdate } from "~/hooks/useTraceUpdate";

interface ThreadViewProps {
  threadContent: PossibleThreadContent;
  inView: boolean;
  liveMode?: boolean;
  scrolledTo?: boolean;
  fake?: boolean;
  feedType?: string;
}

export const ThreadView = React.memo(
  ({
    threadContent,
    inView,
    liveMode,
    scrolledTo,
    fake,
    feedType
  }: ThreadViewProps) => {
    if (!!threadContent.thread?.deleted || !threadContent.content) {
      return <DeletedThread threadIndexed={threadContent.thread} />;
    }
    if (threadContent.content === null) {
      return <LoadingThread threadIndexed={threadContent.thread} />;
    }

    return (
      <FullThread
        scrolledTo={scrolledTo}
        threadContent={threadContent as ThreadContent}
        inView={inView}
        liveMode={liveMode}
        fake={fake}
        feedType={feedType}
      />
    );
  }
);

ThreadView.displayName = "ThreadView";

interface DeletedThreadProps {
  threadIndexed: ThreadIndexed;
}

function DeletedThread({ threadIndexed }: DeletedThreadProps) {
  return <div></div>;
  const { author } = threadIndexed;
  return (
    <ThreadContainer className="border-b border-pri dark:border-pri-d">
      <SmallAvatar12 author={author} />
      <ThreadBody>
        <ThreadHeader>
          <DisplayName authorName={author} name={""} />
          <AccountName author={author} />
        </ThreadHeader>
        <span className="text-red-500 dark:text-red-800">
          This thread is deleted.
        </span>
      </ThreadBody>
    </ThreadContainer>
  );
}

export const ImageDimensionsContext = React.createContext<any>({});

export function LoadingPostPreview() {
  return (
    <div className="border border-pri dark:border-pri-d rounded">
      <div className="divide-y divide-pri dark:divide-pri-d motion-safe:animate-pulse">
        <div className="h-64 w-full bg-slate-200 dark:bg-zinc-700 overflow-hidden" />
        <div className="justify-center h-16 px-2 py-2 flex flex-col gap-2">
          <div className="grid gap-2 grid-cols-3">
            <div className="h-2 bg-slate-200 dark:bg-zinc-700 rounded col-span-1" />
          </div>
          <div className="h-2 bg-slate-200 dark:bg-zinc-700 rounded" />
          <div className="grid gap-5 grid-cols-3">
            <div className="h-2 bg-slate-200 dark:bg-zinc-700 rounded col-span-2" />
          </div>
        </div>
      </div>
    </div>
  );
}
