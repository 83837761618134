import { FloatingPortal } from "@floating-ui/react";
import React, { useState, useEffect, useRef } from "react";
import useOnClickOutside from "~/hooks/useClickOutside";
import { FloatingHTMLOverlay } from "./FloatingHTMLOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface ThreadImagesProps {
  visible: boolean;
  images: string[];
  currentIndex: number;
  onClose: () => void;
}

export default function ImageViewer({
  visible,
  images,
  currentIndex,
  onClose
}: ThreadImagesProps) {
  const viewer = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    ratio: 0
  });
  const [zoomLevel, setZoomLevel] = useState(1.0); // Add zoom level state

  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = err => cb(err);
    img.src = url;
  };



  useEffect(() => {
    if (!images[currentIndex]) return;

    getMeta(images[currentIndex], (err, img) => {
      if (err) return;

      setDimensions({
        width: img.naturalWidth,
        height: img.naturalHeight,
        ratio: img.naturalHeight / (img.naturalWidth / 100)
      });


    });
  }, [images, currentIndex]);

  // close on click outside
  useOnClickOutside(viewer, () => onClose());

  useEffect(() => {
    const handleKeyDown = ev => {
      if (ev.key === "Escape") {

        onClose();
        
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Add event listeners for zooming
  const handleWheel = e => {
    e.preventDefault();

    // Adjust zoom level based on the scroll direction
    if (e.deltaY > 0) {
      zoomOut();
    } else {
      zoomIn();
    }
  };

  const zoomIn = () => {
    // Increase zoom level, but set a maximum limit
    const newZoomLevel = Math.min(zoomLevel + 0.1, 3.0);
    setZoomLevel(newZoomLevel);
  };

  const zoomOut = () => {
    // Decrease zoom level, but set a minimum limit
    const newZoomLevel = Math.max(zoomLevel - 0.1, 0.5);
    setZoomLevel(newZoomLevel);
  };
  
  if (!visible || !images.length) return null;
  return (
    <FloatingPortal>
      <FloatingHTMLOverlay className="bg-black bg-opacity-70 z-[1000] w-screen h-screen top-0 left-0 overflow-hidden">
        <div className="relative flex flex-col justify-center items-center w-full h-full">
          <button
            onClick={onClose}
            className="absolute top-5 right-5 flex items-center justify-center w-10 h-10 rounded-full bg-pri/[.25] dark:bg-pri/[.1] hover:bg-pri/[.35] dark:hover:bg-pri/[.25] transition-colors z-10"
          >
            <FontAwesomeIcon
              icon={faTimes}
              className="text-pri dark:text-pri-d"
            />
          </button>

          <div
            className="animate-reveal-form relative block overflow-hidden w-full h-0 z-10"
            style={{
              paddingBottom: `${dimensions.ratio}%`,
              // Apply zoom level to the image
              transform: `scale(${zoomLevel})`,
              transition: "transform 0.2s" // Add a smooth transition effect
            }}
            onWheel={handleWheel} // Attach the zooming logic
          >
            <img
              ref={viewer}
              src={images[currentIndex]}
              alt=""
              className=" absolute block left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 w-auto h-auto max-h-[calc(100vh-4rem)] max-w-[calc(100vw-4rem)] object-center object-contain"
            />
          </div>
        </div>
      </FloatingHTMLOverlay>
    </FloatingPortal>
  );
}

