import { ThreadIndexed } from "~/utils/thread";
import AccountName from "../format/AccountName";
import LikeCount from "../format/LikeCount";
import Reputation from "../format/Reputation";
import { SmallAvatar12 } from "../format/SmallAvatar";
import { ReplyCount } from "../posts/Comments";
import { LoadingIndicator } from "./LoadingIndicator";
import {
  ThreadContainer,
  ThreadBody,
  ThreadHeader,
  ThreadFooter
} from "./ThreadViewHelpers";

interface LoadingThreadProps {
  threadIndexed: ThreadIndexed;
}

export function LoadingThread({ threadIndexed }: LoadingThreadProps) {
  const { author, children } = threadIndexed;

  return (
    <ThreadContainer>
      <SmallAvatar12 author={author} />
      <ThreadBody>
        <ThreadHeader>
          <AccountName author={author} />
          <Reputation reputation={0} />
        </ThreadHeader>
        <LoadingIndicator />
        <ThreadFooter>
          <ReplyCount replyCount={children} />
          <LikeCount likeCount={0} scotContent={null} />
        </ThreadFooter>
      </ThreadBody>
    </ThreadContainer>
  );
}
