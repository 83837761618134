import * as React from "react";

interface PostFooter {
  author: string;
  permlink: string;
  body: string;
}

export const PostFooterText = ({ author, permlink, body }: PostFooter) => {
  const link = `https://inleo.io/@${author}/${permlink}`;
  const footer = `Posted Using [InLeo Alpha](${link})`;
  return body + "\n\n" + footer;
};

