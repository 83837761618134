import React, { forwardRef } from "react";
import classNames from "classnames";

interface SubmitButtonProps {
  handleClick: any;
  posted: any;
  disabled: any;
  type: any;
  MarkdownEditorTypes: any;
}

const SubmitButton = forwardRef(
  (
    {
      handleClick,
      posted,
      disabled,
      type,
      MarkdownEditorTypes
    }: SubmitButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        ref={ref}
        onClick={handleClick}
        about={"CTRL + ENTER"}
        title={"CTRL + ENTER"}
        className={classNames(
          "px-5 py-2 text-sm font-semibold text-pri rounded-3xl bg-acc hover:bg-acc-hov disabled:cursor-not-allowed disabled:opacity-75 transition-all duration-300",
          {
            "bg-green-600 disabled:bg-green-500 disabled:opacity-100 duration-30":
              posted
          }
        )}
        disabled={disabled}
      >
        {posted ? "Onchain" : MarkdownEditorTypes[type]}
      </button>
    );
  }
);

export default SubmitButton;
