import classNames from "classnames";
import Images from "./Images";
import { isThreadLink } from "~/utils/markdown";
import { ClientOnly } from "remix-utils/client-only";
import { useAppStore } from "~/store";
import { isSSR } from "~/utils/ssr";

interface AnchorProps {
  href: string;
  title: string;
  children: string[];
}

const POST_REGEX = new RegExp(
  /https:\/\/(peakd\.com|hive\.blog|leofinance\.io|ecency\.com)\/\S*@\S+\/\S+/
);
const YOUTUBE_REGEX = new RegExp(
  /(?:https?:)?(?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gim
);

function isImageLink(value: string): boolean {
  if (!value) return false;

  if (value.endsWith(".png")) {
    return true;
  }
  if (value.endsWith(".jpg")) {
    return true;
  }
  if (value.endsWith(".jpeg")) {
    return true;
  }
  if (value.endsWith(".gif")) {
    return true;
  }
  if (value.endsWith(".webp")) {
    return true;
  }
  return false;
}

export default function Anchor({ href, title, children }: AnchorProps) {
  const placeholderVideo = useAppStore(
    store => store.markdown.placeholderVideo
  );
  //console.log({href, title, children})
  if (isImageLink(href)) {
    return <Images alt={title ?? children} src={href} />;
  }

  if (
    (href.startsWith("https://3speak.tv/watch?v=") ||
      href.startsWith("https://3speak.tv/embed?v=")) &&
    !children?.length
  ) {
    const [author, permlink] = href
      .replaceAll("https://3speak.tv/watch?v=", "")
      .replaceAll("https://3speak.tv/embed?v=", "")

      .split("/");

    const isMobile = isSSR()
      ? false
      : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        );

    return placeholderVideo?.link === href ? (
      <video className="w-full h-full object-cover" controls>
        <source src={placeholderVideo?.preview} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ) : (
      <ClientOnly>
        {() => (
          <iframe
            width="100%"
            height={isMobile ? "fit" : "315"}
            src={`https://3speak.tv/embed?v=${author}/${permlink}`}
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </ClientOnly>
    );
  }
  // buraya bak deniz

  const isYoutubeURL =
    href?.includes("https://youtu") || href?.includes("https://www.youtu");

  const decide = typeof children?.[0] === "string" ? children?.[0] : "";
  const threeSpeak = decide.startsWith("https://3speak.tv/watch?v=leoshorts");
  return (
    <a
      href={href}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        "no-underline !font-sans !text-acc leading-none break-words",
        {
          "hover:underline": !isThreadLink(href),
          "w-full": isThreadLink(href),
          inline: !isYoutubeURL,
          flex: isYoutubeURL
        }
      )}
      style={{
        wordBreak: "normal",
        whiteSpace: "normal"
      }}
      onClick={ev => ev.stopPropagation()}
    >
      {threeSpeak ? <p>View on 3Speak</p> : children}
    </a>
  );
}
