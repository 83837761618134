import {
  useState,
  type ReactNode,
  type MouseEvent,
  memo,
  useEffect
} from "react";
import { Link, PrefetchPageLinks, useNavigate } from "@remix-run/react";
import { useScotFollowing } from "~/hooks/contentHooks";
import { SmallAvatar14 } from "./format/SmallAvatar";
import classNames from "classnames";
import {
  FloatingFocusManager,
  FloatingPortal,
  Placement,
  flip,
  offset,
  safePolygon,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole
} from "@floating-ui/react";
import { followAccount, unFollowAccount } from "~/utils/transactions";
import Reputation from "./format/Reputation";
import { useAppStore } from "~/store";
import { DynamicImport } from "./DynamicImport";
import { LazyMotion, m } from "framer-motion";
import { cache } from "~/utils/cache";
import DisplayName from "~/components/format/DisplayName";
import { isSSR } from "~/utils/ssr";
import NotFound from "~/routes/$";
import * as React from "react";

const loadFeatures = () =>
  import("~/components/framermotion/features").then(res => res.default);

interface ProfileLinkProps {
  accountName: string;
  children: ReactNode;
  className?: string;
  referrer?: string;
  position?: Placement;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const ProfileLink = memo(
  ({
    accountName,
    children,
    className,
    referrer,
    position,
    onClick
  }: ProfileLinkProps) => {
    const navigate = useNavigate();
    const activeAccount = useAppStore(store => store.account.activeAccount);

    // const [, account] = useHiveAccount(accountName);
    const [account, setAccount] = useState(null);
    const [hover, setHover] = useState(false);
    let { isFollowing, setIsFollowing } = useScotFollowing({
      account: account?.name || "",
      follower: activeAccount?.name,
      enabled: !!account
    });

    const { x, y, reference, floating, strategy, context } = useFloating({
      open: hover,
      onOpenChange: open => setHover(open),
      middleware: [offset(5), flip()],
      placement: position ?? "bottom-start",
      strategy: "fixed"
    });

    const hoverEvent = useHover(context, {
      delay: 300,
      move: true,
      handleClose: safePolygon()
    });

    const role = useRole(context, { role: "tooltip" });

    const dismiss = useDismiss(context, {
      ancestorScroll: true
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      hoverEvent,
      role,
      dismiss
    ]);

    useEffect(() => {
      if (!accountName || account || !hover) return;
      if (accountName === account?.name) return;
      //console.log({ hover, accountName, account });
      (async () => {
        try {
          const hiveContent = await cache.getAccount(accountName);
          setAccount(hiveContent as any);
        } catch {}
      })();
    }, [hover, accountName, account]);

    const postingName =
      account?.posting_json_metadata?.profile?.name ?? accountName ?? "";

    const handleFollow = async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (!activeAccount?.name || !account?.name) return;

      if (isFollowing) {
        await unFollowAccount(activeAccount?.name, account?.name);
        setIsFollowing(false);
      } else {
        await followAccount(activeAccount?.name, account?.name);
        setIsFollowing(true);
      }
    };

    return (
      <>
        {!referrer ? (
          <div
            className={className}
            onClick={ev => navigate(`/profile/${accountName}`)}
          >
            {children}
          </div>
        ) : (
          <div
            className="flex items-center"
            ref={reference}
            {...getReferenceProps}
          >
            {children}

            <FloatingPortal>
              <LazyMotion features={loadFeatures}>
                {hover ? (
                  <FloatingFocusManager
                    context={context}
                    initialFocus={undefined}
                    order={["reference", "floating"]}
                    modal={true}
                  >
                    <m.div
                      initial={{ opacity: 0, y: 4, scale: 0.99 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{ opacity: 0, y: 4, scale: 0.99 }}
                      transition={{ duration: 0.2, ease: "easeInOut" }}
                      ref={floating}
                      {...getFloatingProps}
                      style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        zIndex: 10001
                      }}
                      className={classNames(className)}
                      id={`${accountName}-${referrer}`}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      <div
                        className={classNames(
                          "relative flex flex-col px-1.5 py-2.5 rounded-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d text-xs shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)]"
                        )}
                      >
                        <PrefetchPageLinks page={`/profile/${accountName}`} />
                        <div className="px-6 py-4 pl-2 flex flex-col w-[26rem] h-fit z-10">
                          <div className="flex flex-1 flex-row items-center text-pri dark:text-pri-d">
                            <Link
                              to={`/profile/${accountName}`}
                              className="flex flex-1 flex-row items-center cursor-pointer hover:opacity-80"
                            >
                              <div className="relative flex shrink-0 w-14 h-14">
                                <SmallAvatar14 author={accountName} />
                                <div className="absolute -right-1 -bottom-1">
                                  <Reputation
                                    reputation={Number(
                                      account?.reputation || 0
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="pl-3 flex flex-col gap-y-1 w-full">
                                {/*<span className="text-base font-bold leading-none">*/}
                                {/*  {account?.posting_json_metadata?.profile?.name ||*/}
                                {/*    accountName}*/}
                                {/*</span>*/}
                                <DisplayName
                                  name={
                                    account?.posting_json_metadata?.profile
                                      ?.name || accountName
                                  }
                                  authorName={accountName}
                                />
                                <span className="text-xs text-gray-600 dark:text-gray-500">
                                  @{accountName}
                                </span>
                              </div>
                            </Link>

                            {activeAccount?.name &&
                              account?.name !== activeAccount?.name && (
                                <div className="flex ml-auto">
                                  <button
                                    type="button"
                                    className={classNames(
                                      "flex py-1.5 px-5 rounded-full text-xs font-semibold hover:opacity-80 transition-opacity duration-150",
                                      {
                                        "bg-pri-d dark:bg-pri text-pri-d dark:text-pri":
                                          !isFollowing,
                                        "bg-gray-300 dark:bg-zinc-700 text-pri/80 dark:text-pri-d/80":
                                          isFollowing
                                      }
                                    )}
                                    onClick={event => handleFollow(event)}
                                  >
                                    {isFollowing ? "Unfollow" : "Follow"}
                                  </button>
                                </div>
                              )}
                          </div>
                          <div className="flex flex-col gap-y-4 h-fit py-4 max-w-[22rem] overflow-hidden">
                            <p className="text-sm text-ellipsis break-word leading-5 text-pri/90 dark:text-pri-d/90">
                              {account?.posting_json_metadata?.profile?.about}
                            </p>

                            <div className="flex items-center gap-x-3">
                              <span className="text-sm">
                                <span className="text-pri dark:text-pri-d font-bold">
                                  {account?.followers || 0}
                                </span>{" "}
                                <span className="text-pri/60 dark:text-pri-d/60">
                                  followers
                                </span>
                              </span>

                              <span className="text-sm">
                                <span className="text-pri dark:text-pri-d font-bold">
                                  {account?.following || 0}
                                </span>{" "}
                                <span className="text-pri/60 dark:text-pri-d/60">
                                  following
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="w-full">
                            <DynamicImport
                              resolver={import("~/components/LeoPowerBadge")}
                              props={{ accountName }}
                            />
                          </div>
                        </div>
                      </div>
                    </m.div>
                  </FloatingFocusManager>
                ) : null}
              </LazyMotion>
            </FloatingPortal>
          </div>
        )}
      </>
    );
  }
);

ProfileLink.displayName = "ProfileLink";
export default ProfileLink;
