import { ReactNode, useMemo } from "react";
import { cn } from "~/utils/cn";

interface PostInteractions {
  children: ReactNode;
  id?: string;
  className: string | null;
}

export const PostInteractions = ({
  id,
  children,
  className
}: PostInteractions) => {
  return (
    <div
      id={id}
      className={cn(
        "flex flex-row justify-between items-center w-full pt-1",
        className
      )}
    >
      {children}
    </div>
  );
};

