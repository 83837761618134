import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "@remix-run/react";
import classNames from "classnames";
import React, {
  useState,
  useMemo,
  useEffect,
  lazy,
  Suspense,
  memo
} from "react";
// @ts-ignore
import { ClientOnly } from "remix-utils/client-only";

import { useScotContent, useSimpleAnalytics } from "~/hooks/contentHooks";
import {
  findFromBookmarks,
  removeFromBookmarks,
  writeLocalStorageBookmarks
} from "~/utils/bookmarks";
import { BookmarkIcon } from "../bookmarks/BookmarkIcon";
import { useAppStore } from "~/store";
import { useParsedBody } from "~/utils/markdown";
import type { ThreadContent } from "~/utils/thread";
import AccountName from "../format/AccountName";
import DisplayName from "../format/DisplayName";
import LikeCount, {
  useLikeToggleState,
  useLiked,
  LikeSlider,
  DownVoteSlider
} from "../format/LikeCount";
import PollView from "../format/PollView";
import { PostInteractions } from "~/components/posts/PostInteractions";
import ReplyCount from "../format/ReplyCount";
import Reputation from "../format/Reputation";
import { SmallAvatar14 } from "../format/SmallAvatar";
import ImageViewer from "../ImageViewer";
import Payout from "../Payout";
import Popover from "../Popover";
import { TimeSincePublish } from "../TimeSincePublish";
import { LoadMoreThread } from "./LoadMoreThread";
import { ThreadFirstChild } from "./ThreadFirstChild";
import { ImageDimensionsContext } from "./ThreadView";
import LoadOnlyReply, {
  ThreadContainer,
  ThreadBody,
  ThreadHeader,
  ThreadText,
  ThreadFooter
} from "./ThreadViewHelpers";
import { cache } from "~/utils/cache";
import LoadingIndicatorBadge from "../badges/LoadingIndicatorBadge";
import { calculateReputation } from "~/utils/hive";
import ProfileLink from "~/components/ProfileLink";
import ControlledThreadOptions from "./ControlledThreadOptions";
import ThreadOptions from "./ThreadOptions";
import { useTraceUpdate } from "~/hooks/useTraceUpdate";

interface FullThreadProps {
  threadContent: ThreadContent;
  inView?: boolean;
  liveMode?: boolean;
  continues?: boolean;
  norecursive?: boolean;
  scrolledTo?: boolean;
  fake?: boolean;
  feedType?: string;
}

export const FullThread = memo(
  ({
    threadContent,
    inView,
    liveMode,
    continues,
    norecursive,
    scrolledTo,
    feedType,
    fake
  }: FullThreadProps) => {
    const location = useLocation();
    const { content, thread } = threadContent;
    if (!content) return <div></div>;
    const { author, children, permlink } = thread;
    const {
      author_reputation: reputation,
      body,
      active_votes,
      created
    } = content;
    const [activeAccount, threadcasts, loadingIndicator] = useAppStore(
      store => [
        store.account.activeAccount,
        store.discussion.threadcasts,
        store.loadingIndicator
      ]
    );

    const parsedBody = useParsedBody(body);
    const navigate = useNavigate();

    const [imageViewerOpen, setImageViewerOpen] = useState(false);
    const [imageViewerSource, setImageViewerSource] = useState("");

    const [premium, setPremium] = useState(false);

    const handleClick = e => {
      if (!document.getSelection()?.isCollapsed) {
        return;
      }

      if ((e.target as HTMLElement).hasAttribute("data-prevent-routing")) {
        return;
      }

      if (
        ((e.target as HTMLElement).tagName === "IMG" ||
          (e.target as HTMLElement).nodeName === "IMG") &&
        !(e.target as HTMLElement).hasAttribute("data-prevent-view")
      ) {
        setImageViewerOpen(true);
        setImageViewerSource((e.target as HTMLImageElement).src);
        return;
      }
      if (e.ctrlKey || e.metaKey) {
        window.open(
          `https://inleo.io/threads/view/${threadContent?.content?.author}/${threadContent?.content?.permlink}`,
          "_blank"
        );
      } else {
        navigate(`/threads/view/${author}/${permlink}`);
      }
    };

    const authorPerm = useMemo(
      () => ({ author, permlink }),
      [author, permlink]
    );

    const isPostsPage = useMemo(
      () => location?.pathname?.startsWith("/@"),
      [location?.pathname]
    );

    const isNotificationsPage = useMemo(
      () => location?.pathname === "/notifications",
      [location?.pathname]
    );

    const isFollowingPage = useMemo(
      () =>
        location?.pathname === "/threads/following" ||
        location?.pathname === "/threads/foryou",
      [location?.pathname]
    );

    const isThreadFullView = useMemo(
      () => location?.pathname?.startsWith("/threads/view"),
      [location?.pathname]
    );

    // Vote number coming from cache

    const [state, scotContent, setScotContent] = useScotContent(thread);

    const [likeToggle, setLikeToggle] = useLikeToggleState();
    const [downvoteToggle, setDownvoteToggle] = useState(false);

    const [shortDetails, setShortDetails] = useState({});

    const isShortsVideo = content?.body?.includes(
      "https://3speak.tv/watch?v=leoshorts"
    );

    let regex, match;

    if (isShortsVideo) {
      try {
        regex = /https:\/\/3speak\.tv\/watch\?v=([^\/]+)\/([^\/]+)/;
        match = (content?.body || "")?.match(regex) || ["", "", ""];
      } catch {
        match = ["", "", ""];
      }
    } else {
      match = ["", "", ""];
    }

    const [, shortsAuthor, shortsPermlink] = match;

    useEffect(() => {
      void (async function () {
        if (shortsAuthor === "" || shortsPermlink === "") return;
        const scotContent = await cache.getScotContent({
          author: shortsAuthor,
          permlink: shortsPermlink?.split(" ")[0].replaceAll(" ", "")?.trim()
        });
        const hiveContent = await cache.getContent({
          author: shortsAuthor,
          permlink: shortsPermlink?.split(" ")[0].replaceAll(" ", "")?.trim()
        });

        setShortDetails({
          scotContent,
          hiveContent
        });
      })();
    }, [shortsAuthor, shortsPermlink, isShortsVideo]);

    const liked = useLiked(
      activeAccount?.name ?? null,
      isShortsVideo ? shortDetails?.scotContent : (scotContent as any)
    );

    const [bookmark, setBookmark] = useState(false);

    const isActive = useMemo(() => activeAccount !== null, [activeAccount]);

    const first_child = useMemo(() => {
      let _first_child =
        (thread?.replies || [])?.at(0)?.replaceAll("@")?.split("/") || [];

      let first_child = _first_child.length
        ? {
            author: _first_child[0],
            permlink: _first_child[1]
          }
        : false;

      return first_child;
    }, [thread]);

    const isFirstReplyOwners = useMemo(
      () => first_child?.author === author,
      [first_child, author]
    );

    const isOwnerReplyingSelf = useMemo(
      () => thread?.author === content?.parent_author,
      [content]
    );
    const isThreadStorm = isThreadFullView ? isOwnerReplyingSelf : true;

    const isRepliesFewer = useMemo(
      () =>
        thread?.replies?.length <= 3 &&
        thread?.replies?.length > 1 &&
        isFirstReplyOwners === false &&
        !isNotificationsPage,
      [thread?.replies, isFirstReplyOwners, isNotificationsPage]
    );

    const isOnlyReply = useMemo(
      () =>
        thread?.replies?.length! === 1 &&
        isFirstReplyOwners === false &&
        !isNotificationsPage,
      [thread?.replies, isFirstReplyOwners, isNotificationsPage]
    );

    const isParentThread = useMemo(
      () => content?.parent_author === "leothreads" && !isNotificationsPage,
      [content?.parent_author, isNotificationsPage]
    );

    useEffect(() => {
      setBookmark(findFromBookmarks({ author, permlink }));
    }, [setBookmark]);

    const { pageViews } = useSimpleAnalytics(
      `/threads/view/${author}/${permlink}`
    );

    const metadata = useMemo(() => {
      let metadata;
      try {
        metadata = JSON.parse(content?.json_metadata);
      } catch {
        metadata = {};
      }
      return metadata;
    }, [content]);

    const excludedDomains = useMemo(
      () => ["https://labs.inleo.io/threads", "https://inleo.io/threads"],
      []
    );
    const hasLinks =
      metadata?.links?.length! > 0
        ? metadata?.links?.every(
            link => !excludedDomains?.some(domain => link?.includes(domain))
          )
        : false;

    const isPoll = metadata?.isPoll && metadata?.pollOptions;
    //console.log({ isPoll, metadata });

    const handleBookmark = (event: React.MouseEvent) => {
      event.stopPropagation();
      if (findFromBookmarks({ author, permlink })) {
        removeFromBookmarks({ author, permlink });
        setBookmark(false);
      } else {
        writeLocalStorageBookmarks({ author, permlink });
        setBookmark(true);
      }
    };

    const handleParentClick = (ev: React.MouseEvent) => {
      ev.stopPropagation();
      navigate(
        `/threads/view/${content?.parent_author}/${content?.parent_permlink}`
      );
    };

    const [controlledThreadOptions, setControlledThreadOptions] =
      React.useState(false);

    const [cursor, setCursor] = React.useState({ x: 0, y: 0 });

    const in_threadcast = threadcasts?.find?.(
      t =>
        t?.author === author || t.replies?.find(r => r.split("/")[0] === author)
    );

    //if (!isPostsPage && metadata?.app?.split("/")[0] === "ecency") return null;
    // hide_links
    if (
      !isPostsPage &&
      //premium?.is_premium === false &&
      hasLinks &&
      //calculateReputation(reputation ?? 0).toFixed(0) < 80 &&
      !location.pathname.startsWith("/threads/view/") &&
      !location.pathname.startsWith("/profile") &&
      !location.pathname.startsWith("/notifications") &&
      !location.pathname.startsWith("/threads/following") &&
      !location.pathname.startsWith("/threads/foryou")
    ) {
      return <div className="h-[1px] max-h-[1px] opacity-0"></div>;
    }

    const isForYou = location.pathname.startsWith("/threads/foryou");

    if (thread?.parent_author && thread?.parent_permlink && !isThreadFullView) {
      if (thread.parent_author !== "leothreads" && norecursive !== true) {
        return (
          <>
            <LoadOnlyReply
              author={thread?.parent_author!}
              permlink={thread?.parent_permlink}
              continues={true}
              notop={true}
            />
            <LoadOnlyReply
              author={thread?.author!}
              permlink={thread?.permlink}
              continues={false}
              notop={false}
            />
          </>
        );
      }
    }

    const MemoizedThreadOptions = useMemo(
      () => (
        <Suspense fallback={<></>}>
          <ThreadOptions
            threadContent={{ thread, content }}
            onDownVoteCallback={setDownvoteToggle}
          />
        </Suspense>
      ),
      [content?.permlink]
    );

    const paidOut = useMemo(
      () => new Date(content?.cashout_time) < new Date(),
      [content?.permlink]
    );

    const shouldCollapse = useMemo(
      () => content?.body?.length > 600,
      [content?.body]
    );

    return (
      <div
        key={`${content?.permlink}-view`}
        className={classNames("relative", {
          "border-b border-pri dark:border-pri-d group sm:hover:bg-pri-hov sm:dark:hover:bg-pri-hov-d":
            !(isFirstReplyOwners || isRepliesFewer || isOnlyReply || continues),
          "animate-reveal": scrolledTo
        })}
        onClick={ev =>
          isFollowingPage && !isParentThread
            ? handleParentClick(ev)
            : handleClick(ev)
        }
        onContextMenu={event => {
          event.preventDefault();
          setCursor({
            x: event.clientX + 6,
            y: event.clientY + 6
          });
          setControlledThreadOptions(true);
        }}
      >
        <ThreadContainer className="!gap-x-0">
          <Suspense fallback={<></>}>
            <ControlledThreadOptions
              threadContent={threadContent}
              open={controlledThreadOptions}
              setOpen={setControlledThreadOptions}
              cursor={cursor}
              onDownVoteCallback={() => setDownvoteToggle(true)}
            />
          </Suspense>

          <div className="flex flex-col !justify-between pr-4">
            <div className="relative">
              <SmallAvatar14 author={author} />
              {!in_threadcast && (
                <div className="absolute -bottom-0.5 -right-0.5">
                  <Reputation
                    reputation={
                      fake || thread?.fake
                        ? reputation
                        : calculateReputation(reputation || 0) || 0
                    }
                  />
                </div>
              )}
            </div>
            {(isFirstReplyOwners ||
              isRepliesFewer ||
              isOnlyReply ||
              continues) && (
              <div className="w-[1px] -z-30 -mt-6 pt-2 h-full bg-zinc-300 dark:bg-zinc-700 rounded-full self-center" />
            )}
          </div>
          <ThreadBody>
            <ThreadHeader>
              <div className="flex flex-row mt-1">
                <div className="flex flex-col">
                  <div className="flex flex-row items-center gap-2">
                    <Suspense fallback={<></>}>
                      <ProfileLink
                        accountName={author}
                        referrer={content?.permlink}
                      >
                        <DisplayName
                          authorName={author}
                          name={content?.author}
                          premium={premium}
                        />
                      </ProfileLink>
                    </Suspense>
                  </div>

                  <div className="flex flex-row items-center gap-1">
                    <Suspense fallback={<></>}>
                      <ProfileLink
                        accountName={author}
                        className="leading-none"
                      >
                        <AccountName author={author} />
                      </ProfileLink>
                    </Suspense>

                    {isForYou || (
                      <>
                        <span className="flex text-gray-500"> · </span>
                        <TimeSincePublish publishTime={created} utc={false} />
                      </>
                    )}
                  </div>
                  {loadingIndicator.items.find(
                    item =>
                      item.type === "edit" &&
                      item.category === "thread" &&
                      item.id == permlink
                  ) && (
                    <LoadingIndicatorBadge
                      operationType="edit"
                      operationCategory="thread"
                    />
                  )}
                </div>
              </div>

              {MemoizedThreadOptions}
            </ThreadHeader>
            {!isParentThread && (
              <span className="relative flex items-center pb-3 gap-x-2">
                <span className="w-6 h-[1px] bg-gray-300 dark:bg-zinc-600 rounded-lg" />
                <span
                  className="text-xs text-gray-400 dark:text-zinc-500 hover:underline"
                  onClick={handleParentClick}
                >
                  Replying to{" "}
                  <span className="text-acc font-bold">
                    @{content.parent_author}
                  </span>
                </span>
              </span>
            )}

            <ThreadText>
              <ImageDimensionsContext.Provider
                value={metadata?.dimensions! || {}}
              >
                <div
                  style={
                    shouldCollapse
                      ? {
                          height: 600,
                          overflow: "hidden",
                          position: "relative"
                        }
                      : {}
                  }
                >
                  {parsedBody || <p> error </p>}
                  {shouldCollapse && (
                    <div
                      className="absolute bottom-0 h-9 w-full
                    bg-gradient-to-b from-transparent to-white sm:group-hover:to-zinc-100
                    dark:bg-gradient-to-b dark:to-zinc-900 dark:sm:group-hover:to-[#202023]
                     "
                    >
                      {" "}
                    </div>
                  )}
                </div>

                {shouldCollapse && (
                  <div className="z-[1] flex items-center sm:hover:px-4 py-2  cursor-pointer transition-all w-full">
                    <b className="text-acc ">Show More</b>
                  </div>
                )}
              </ImageDimensionsContext.Provider>
              {isPoll && (
                <ClientOnly>
                  {() => (
                    <PollView
                      author={activeAccount?.name}
                      authorPerm={authorPerm}
                      pollOptions={metadata?.pollOptions!}
                    />
                  )}
                </ClientOnly>
              )}
            </ThreadText>
            <ThreadFooter>
              <PostInteractions className="relative flex gap-x-4 !p-none -ml-1">
                {/* <div className="flex flex-row items-center gap-2 text-lg py-2"> */}
                <ReplyCount
                  replyCount={children}
                  threadContent={threadContent}
                />
                <ClientOnly>
                  {() => (
                    <LikeCount
                      likeCount={
                        isShortsVideo
                          ? shortDetails?.scotContent?.active_votes?.length
                          : thread?.stats?.total_votes! ||
                            active_votes?.length ||
                            scotContent?.active_votes?.length ||
                            0
                      }
                      scotContent={
                        isShortsVideo ? shortDetails?.scotContent : scotContent
                      }
                      liked={liked}
                      setLikeToggle={isActive ? setLikeToggle : undefined}
                      paidOut={paidOut}
                      className={
                        paidOut && "opacity-25 select-none pointer-events-none"
                      }
                    />
                  )}
                </ClientOnly>
                <div
                  id={`analytics-buybacks-${author}-${permlink}`}
                  className="group/buybacks flex py-0.5 pl-0.5 pr-2 rounded-full text-gray-500 dark:text-zinc-500 hover:text-acc dark:hover:text-acc transition-colors cursor-pointer"
                >
                  <div className="group/buybacks flex items-center text-gray-500 dark:text-zinc-500 group-hover/buybacks:text-acc dark:group-hover/buybacks:text-acc transition-colors cursor-pointer">
                    <div className="flex justify-center items-center w-9 h-9 sm:w-8 sm:h-8 rounded-full group-hover/buybacks:bg-acc/[.15] group-hover/buybacks:text-acc">
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        className="w-5 h-5"
                        fixedWidth
                      />
                    </div>
                    <span className="pl-1 text-lg sm:text-base">
                      {pageViews || 0}
                    </span>
                  </div>
                </div>
                <BookmarkIcon
                  author={content?.author}
                  permlink={content?.permlink}
                />
                {/* </div> */}
                {!fake ? (
                  <Payout
                    scotContent={
                      isShortsVideo ? shortDetails?.scotContent : scotContent
                    }
                    hiveContent={
                      isShortsVideo ? shortDetails?.hiveContent : content
                    }
                  />
                ) : (
                  <div />
                )}

                <ClientOnly>
                  {() =>
                    isActive && (
                      <LikeSlider
                        likeToggle={likeToggle}
                        setLikeToggle={setLikeToggle}
                        setScotContent={setScotContent}
                        accountName={activeAccount?.name}
                        authorPerm={thread}
                      />
                    )
                  }
                </ClientOnly>

                <DownVoteSlider
                  likeToggle={downvoteToggle}
                  setLikeToggle={setDownvoteToggle}
                  setScotContent={setScotContent}
                  accountName={activeAccount?.name}
                  authorPerm={thread}
                />
              </PostInteractions>
            </ThreadFooter>
          </ThreadBody>
        </ThreadContainer>

        {isFirstReplyOwners && <ThreadFirstChild author={author} />}
        {isRepliesFewer && (
          <LoadMoreThread
            author={author}
            permlink={permlink}
            children={children}
            replies={threadContent?.thread?.replies || []}
            firstReplyAuthor={first_child?.author! || author}
          />
        )}
        {first_child && isOnlyReply && (
          <LoadOnlyReply
            author={first_child?.author!}
            permlink={first_child?.permlink!}
          />
        )}

        <ImageViewer
          visible={imageViewerOpen}
          images={[imageViewerSource]}
          currentIndex={0}
          onClose={() => setImageViewerOpen(false)}
        />
      </div>
    );
  }
);

FullThread.displayName = "FullThread";
