import { FloatingPortal } from "@floating-ui/react";
import {
  faXmark,
  faSpinner,
  faBookmark as faBookmarkSolid
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { ClientOnly } from "remix-utils/client-only";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState, useRef, useEffect, Fragment, memo } from "react";
import { toast } from "react-toastify";
import useOnClickOutside from "~/hooks/useClickOutside";
import { FolderPreview } from "./FolderPreview";
import {
  findFromBookmarks,
  removeFromBookmarks,
  addToBookmarks
} from "~/utils/bookmarks";
import { useAppStore } from "~/store";
import type { AuthorPerm } from "~/utils/hive";
import { leocache, type Bookmark } from "~/utils/leocache";
import { isSSR } from "~/utils/ssr";
import { FloatingHTMLOverlay } from "../FloatingHTMLOverlay";
import { BookmarksLoader } from "~/routes/bookmarks";

export const BookmarkIcon = memo(
  ({ author, permlink, className }: AuthorPerm & { className?: string }) => {
    const [
      {
        activeAccount,
        keys,
        bookmarks: bookmarksFromStore,
        setBookmarks: setStoreBookmarks,
        premium: premium_state
      },
      isDarkMode
    ] = useAppStore(store => [store.account, store.settings.dark]);
    const { signature, publicKey, proxy } = keys;

    const [signatureFromStorage] = useState(
      !isSSR() && window.localStorage.getItem("activeAccount")
    );

    const [bookmarks, setBookmarks] = useState(bookmarksFromStore);
    const [bookmarked, setBookmarked] = useState(false);
    const [bookmarking, setBookmarking] = useState(false);
    const [folder, setFolder] = useState<string | null>(null);

    const ref = useRef<HTMLDivElement>(null);
    const [visibility, setVisibility] = useState<boolean>(false);

    useEffect(() => {
      if (bookmarks !== null || bookmarksFromStore || !activeAccount) return;

      void (async function () {
        const account_bookmarks = await leocache.getAccountBookmarks(
          activeAccount.name,
          true
        );

        setStoreBookmarks(account_bookmarks);
        setBookmarks(account_bookmarks);
      })();
    }, [activeAccount]);

    useEffect(() => {
      setBookmarked(!!findFromBookmarks({ bookmarks, author, permlink }));
    }, [bookmarks]);

    const foundedBookmark: Bookmark | false | undefined = findFromBookmarks({
      bookmarks,
      author,
      permlink
    });

    // non-premium users (instant bookmarking)
    const handleBookmark = async (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if ((!signature && !signatureFromStorage) || !activeAccount || !publicKey)
        return;

      if (foundedBookmark) {
        setBookmarking(true);

        await removeFromBookmarks({
          author,
          permlink,
          folder: foundedBookmark?.folder || "",
          account: activeAccount.name,
          signature: signature || signatureFromStorage,
          publicKey,
          proxy
        });

        setBookmarking(false);
        setBookmarked(false);

        // await mutate();
      } else {
        setBookmarking(true);

        await addToBookmarks({
          author,
          permlink,
          folder: "",
          account: activeAccount.name,
          signature: signature || signatureFromStorage,
          publicKey,
          proxy
        });

        setBookmarked(true);
        setBookmarking(false);

        // await mutate();
      }
    };

    // for premium users (folder selection)
    const createBookmark = async () => {
      if (!activeAccount) return;

      setBookmarking(true);

      await addToBookmarks({
        author,
        permlink,
        folder: folder || "",
        account: activeAccount.name,
        signature: signature || signatureFromStorage,
        publicKey,
        proxy
      });
      // await mutate();

      toast("Content successfully bookmarked", {
        type: "success",
        autoClose: 3000,
        theme: isDarkMode ? "dark" : "light"
      });
      setBookmarked(true);
      setVisibility(false);
    };

    // instant remove
    const deleteBookmark = async () => {
      if (!activeAccount || !foundedBookmark) return;

      setBookmarking(true);

      await removeFromBookmarks({
        author,
        permlink,
        folder: foundedBookmark.folder,
        account: activeAccount.name,
        signature: signature || signatureFromStorage,
        publicKey,
        proxy
      });

      setBookmarking(false);
      setBookmarked(false);

      // await mutate();
    };

    useEffect(() => {
      if (!visibility) {
        setFolder(null);
      }
    }, [visibility]);

    useOnClickOutside(ref, () => setVisibility(false));

    const emptyFolder: Bookmark = {
      account: activeAccount?.name,
      folder: "",
      permlinks: []
    };

    return (
      <ClientOnly>
        {() => (
          <Fragment>
            <FloatingPortal>
              {visibility && (
                <FloatingHTMLOverlay
                  onClick={ev => ev.stopPropagation()}
                  className="duration-50 backdrop-blur-sm bg-overlay z-[1000] flex justify-center py-[10vh]"
                >
                  <div
                    ref={ref}
                    className="animate-reveal-form relative flex-0 bg-pri dark:bg-pri-d w-full m-4 pc:m-0 sm:w-[440px] rounded-xl border border-pri dark:border-pri-d h-min"
                  >
                    <div
                      onClick={() => setVisibility(false)}
                      className="absolute p-2 right-4 top-4 h-10 w-10 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </div>

                    <div className="flex flex-col pt-6 px-6 gap-y-1">
                      <strong className="font-bold text-xl">
                        Select Folder
                      </strong>
                      <span className="text-sm text-pri/60 dark:text-pri-d/60">
                        You can choose a folder to categorize your bookmarks.
                      </span>
                    </div>

                    <div className="relative flex flex-1 flex-col overflow-hidden">
                      <div className="flex flex-1 flex-col max-h-[320px] overflow-y-auto px-6 py-6 pt-4 divide-y divide-solid divide-pri dark:divide-pri-d">
                        {bookmarks === null ? (
                          <BookmarksLoader />
                        ) : bookmarks.length > 0 ? (
                          bookmarks.map((bookmark: Bookmark, index: number) => (
                            <FolderPreview
                              key={index}
                              folder={bookmark}
                              selected={folder}
                              onClick={setFolder}
                            />
                          ))
                        ) : (
                          <FolderPreview
                            folder={emptyFolder}
                            selected={folder}
                            onClick={setFolder}
                          />
                        )}
                      </div>

                      {bookmarks?.length > 3 && (
                        <span
                          className="absolute bottom-0 left-6 right-6 w-[calc(100%-48px)] h-12"
                          style={{
                            backgroundImage: isDarkMode
                              ? "linear-gradient(to top, rgb(0 0 0 / 100%), rgb(0 0 0 / 0))"
                              : "linear-gradient(to top, rgb(255 255 255 / 100%), rgb(255 255 255 / 0))"
                          }}
                        />
                      )}
                    </div>

                    <div className="flex items-center justify-end gap-x-2 mt-4 pb-5 pr-6">
                      <button
                        className="py-2 px-4 rounded-full bg-neutral-200 dark:bg-neutral-700 text-sm font-medium hover:bg-neutral-300 dark:hover:bg-neutral-600 transition-all duration-150"
                        onClick={() => setVisibility(false)}
                      >
                        Cancel
                      </button>

                      <button
                        onClick={createBookmark}
                        className={classNames(
                          "py-2 px-5 rounded-full bg-acc text-sm font-medium hover:opacity-80 transition-all duration-150",
                          {
                            "opacity-60 cursor-not-allowed": bookmarking
                          }
                        )}
                        disabled={bookmarking}
                      >
                        {bookmarking ? (
                          <span className="flex items-center gap-x-2">
                            <span>Saving</span>
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className="animate-spin"
                            />
                          </span>
                        ) : (
                          "Add Bookmark"
                        )}
                      </button>
                    </div>
                  </div>
                </FloatingHTMLOverlay>
              )}
            </FloatingPortal>

            <button
              type="button"
              aria-label="Bookmark"
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                if (bookmarking) return;

                if (foundedBookmark || bookmarked) {
                  deleteBookmark();
                  return;
                }

                if (premium_state.is_premium) {
                  if (bookmarks?.length === 0) {
                    handleBookmark(event);
                    return;
                  }

                  setVisibility(true);
                  return;
                } else {
                  handleBookmark(event);
                  return;
                }
              }}
              className="group/bookmark flex items-center gap-x-2 text-gray-500 dark:text-zinc-500 hover:text-acc dark:hover:text-acc transition-all duration-150 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
              disabled={bookmarking}
            >
              <div
                className={classNames(
                  "flex justify-center items-center w-9 h-9 sm:w-8 sm:h-8 rounded-full group-hover/bookmark:bg-acc/[.15]",
                  className
                )}
              >
                {bookmarked ? (
                  <FontAwesomeIcon
                    icon={faBookmarkSolid}
                    className="w-5 h-5 text-acc"
                    fixedWidth
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faBookmark}
                    className="w-5 h-5"
                    fixedWidth
                  />
                )}
              </div>
            </button>
          </Fragment>
        )}
      </ClientOnly>
    );
  }
);

BookmarkIcon.displayName = "BookmarkIcon";
