import { useState, useEffect } from "react";

export default function ProfileImage({ src, alt, fallbackSrc }: any) {
  const [imgSrc, setImgSrc] = useState(src);

  const handleLoad = async event => {
    const image = event.target;
    try {
      const response = await fetch(image.src, { mode: "cors" });
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result;
        localStorage.setItem("profileImage", dataUrl);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Failed to convert image to base64:", error);
    }
  };

  const handleError = () => {
    const fallbackImage = localStorage.getItem("profileImage") || fallbackSrc;
    setImgSrc(fallbackImage);
  };

  return (
    <img
      src={imgSrc}
      alt={alt}
      onError={handleError}
      onLoad={handleLoad}
      className="profile-image"
    />
  );
}
