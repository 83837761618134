import {
  Dispatch,
  Fragment,
  lazy,
  ReactElement,
  ReactNode,
  SetStateAction,
  Suspense,
  useEffect,
  useMemo,
  useState
} from "react";
import { cache } from "~/utils/cache";
import { FollowCount, PossibleHiveContent } from "~/utils/hive";
import { useParsedBody } from "~/utils/markdown";
import { formatBigNumberWithSuffix } from "~/utils/numeric";
import { LoadingPostPreview } from "../threads/ThreadView";
import DisplayName from "./DisplayName";
import { FullPostPreview } from "./FullPostPreview";
import Reputation from "./Reputation";
import { callSmallAvatar } from "./SmallAvatar";
import { useAppStore } from "~/store";
import { useScotFollowing, useSimpleAnalytics } from "~/hooks/contentHooks";
import { isSSR } from "~/utils/ssr";
import { toast } from "react-toastify";
import { followAccount, unFollowAccount } from "~/utils/transactions";
import { TimeSincePublish } from "../TimeSincePublish";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPause,
  faPlay,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import LeoFinanceBadge from "../LeoFinanceBadge";
import Popover from "../Popover";
import classNames from "classnames";
import { Link, useLocation } from "@remix-run/react";
import { cn } from "~/utils/cn";
import LoadingIndicatorBadge from "../badges/LoadingIndicatorBadge";

const ProfileLink = lazy(() => import("~/components/ProfileLink"));
const PostOptions = lazy(() => import("../posts/PostOptions"));

// interface PostOptions {
//   author: string;
//   permlink: string;
//   activeAccount: ParsedAccount;
// }

// export const PostOptions = ({
//   author,
//   permlink,
//   activeAccount
// }: PostOptions) => {
//   return (
//     <div className="cursor-pointer rounded-full hover:bg-pri-hov/20 p-1 mr-2 mt-5 h-fit">
//       <FontAwesomeIcon icon={faEllipsis} fixedWidth />
//     </div>
//   );
// };

interface PostLightInformationProps {
  commentCount: number;
  reblogCount: number;
}

export const PostLightInformation = ({
  commentCount,
  reblogCount
}: PostLightInformationProps) => {
  return (
    <div className="flex flex-row pl-14 flex-end gap-2 ml-2 text-sm font-semibold text-zinc-500 mt-2">
      <span>{commentCount} comments</span>
      <span>·</span>
      <span>{reblogCount} reblogs</span>
    </div>
  );
};

interface PostBody {
  children: ReactNode;
}

export const PostBody = ({ children }: PostBody) => {
  return <div className="relative m-5 p-1 mt-3">{children}</div>;
};

interface PostBodyHeaderProps {
  displayName: string;
  imageSize: number;
  name: string;
  category?: string;
  json_metadata?: string;
  publishTime?: string;
  readTime?: number | null;
  reputation: number;
  followCount?: FollowCount | null;
  showFollowButton?: boolean;
  content?: any;
  permlink?: string;
  setDownVoteToggle?: Dispatch<SetStateAction<boolean>>;
}

export const PostBodyHeader = ({
  name,
  category,
  json_metadata,
  displayName,
  readTime,
  publishTime,
  imageSize,
  reputation,
  showFollowButton = true,
  followCount,
  content,
  permlink,
  setDownVoteToggle
}: PostBodyHeaderProps) => {
  const { pageViews } = useSimpleAnalytics(`/@${displayName}/${permlink}`);

  const [activeAccount, isDarkMode, loadingIndicator] = useAppStore(store => [
    store.account.activeAccount,
    store.settings.dark,
    store.loadingIndicator
  ]);

  const [followLoading, setFollowLoading] = useState<boolean>(false);
  const { isFollowing, setIsFollowing } = useScotFollowing({
    account: displayName,
    follower: activeAccount?.name ?? ""
  });

  const [following, setFollowing] = useState(isFollowing || false);

  const [speaker, setSpeaker] = useState<undefined | boolean>(undefined);

  const utterance =
    !isSSR() &&
    window["SpeechSynthesisUtterance"] != undefined &&
    new SpeechSynthesisUtterance(
      removeHtmlMarkdownLinks(
        content?.body || "Can't read out loud for this content."
      )
    );

  useEffect(() => {
    window?.speechSynthesis?.cancel();
  }, [permlink]);

  const speak = () => {
    if (!utterance) return;
    if (content?.body && speaker === undefined) {
      window?.speechSynthesis?.speak(utterance as any);
      return setSpeaker(true);
    }
    if (speaker === true) {
      setSpeaker(false);
      window?.speechSynthesis?.pause();
    } else {
      setSpeaker(true);
      window?.speechSynthesis?.resume();
    }
  };

  const handleFollow = async () => {
    if (followLoading) return;

    if (!activeAccount?.name) {
      return toast("Please sign-in to follow this account.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3000
      });
    }

    setFollowLoading(true);

    if (isFollowing) {
      try {
        await unFollowAccount(activeAccount?.name, displayName).then(
          (result: any) => {
            result === undefined && setIsFollowing(!isFollowing);
          }
        );
        setFollowLoading(false);
        setFollowing(false);
      } catch {
        setFollowLoading(false);
      }
    } else {
      try {
        await followAccount(activeAccount?.name, displayName).then(
          (result: any) => {
            result === undefined && setIsFollowing(!isFollowing);
          }
        );
        setFollowLoading(false);
        setFollowing(true);
      } catch {
        setFollowLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-row gap-1">
        <div className="relative">
          {callSmallAvatar({ author: displayName, imageSize })}
          <div className="absolute -bottom-0.5 -right-1">
            <Reputation reputation={reputation} />
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-center pl-2">
          <div className="flex flex-row gap-x-3">
            <div className="flex flex-col">
              <Suspense fallback={<></>}>
                <ProfileLink
                  className="flex"
                  accountName={displayName}
                  referrer={name}
                >
                  <Link to={`/profile/${displayName}`}>
                    <DisplayName name={name} authorName={displayName} />
                  </Link>
                </ProfileLink>
              </Suspense>

              <div
                className="flex items-center gap-1 -mt-0.5"
                itemScope
                itemProp="author"
                itemType="https://schema.org/Person"
              >
                <Suspense fallback={<></>}>
                  <ProfileLink accountName={displayName}>
                    <span
                      itemProp="name"
                      className={classNames(
                        "text-xs text-gray-500 leading-none",
                        {
                          "max-w-[48px] text-ellipsis overflow-hidden block":
                            followCount
                        }
                      )}
                    >
                      @{displayName}
                    </span>
                  </ProfileLink>
                </Suspense>
                {publishTime && (
                  <Fragment>
                    <span className="flex text-gray-500 leading-none font-bold mt-0.5">
                      ·
                    </span>

                    <TimeSincePublish
                      publishTime={publishTime}
                      className="mt-0.5 leading-none"
                    />
                  </Fragment>
                )}
              </div>
              {loadingIndicator.items.find(item => item!.id === permlink) && (
                <LoadingIndicatorBadge
                  operationType="edit"
                  operationCategory="post"
                />
              )}
            </div>
            {displayName && (
              <span className="flex ml-auto">
                {showFollowButton && (
                  <button
                    type="button"
                    title={following ? "Unfollow" : "Follow"}
                    aria-label={following ? "Unfollow" : "Follow"}
                    onClick={handleFollow}
                    className={classNames(
                      "flex items-center gap-x-2 h-fit px-5 py-2 rounded-3xl text-sm font-medium hover:opacity-80 transition-all duration-150 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:opacity-50",
                      {
                        "bg-pri-d dark:bg-pri text-pri-d dark:text-pri":
                          !following,
                        "bg-gray-300 dark:bg-zinc-600 text-pri dark:text-pri-d":
                          following
                      }
                    )}
                    disabled={followLoading}
                  >
                    {following ? "Unfollow" : "Follow"}
                    {followLoading ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        size="sm"
                        fixedWidth
                        className="animate-spin"
                      />
                    ) : null}
                  </button>
                )}
              </span>
            )}
          </div>
        </div>
      </div>

      <PostBodySubHeader
        author={displayName}
        permlink={permlink}
        category={category}
        metadata={json_metadata}
        pageViews={pageViews}
        readTime={readTime}
        content={content}
        speaker={speaker}
        speak={speak}
        setDownVoteToggle={setDownVoteToggle}
      />
    </div>
  );
};

interface PostBodySubHeaderProps {
  author: string;
  permlink: string;
  category: string | undefined;
  metadata: string | undefined;
  pageViews: number | undefined;
  readTime: number | null | undefined;
  content: any | undefined;
  speaker: boolean | undefined;
  setDownVoteToggle: boolean | undefined;
  speak: () => void;
}

function PostBodySubHeader({
  author,
  permlink,
  category,
  metadata,
  pageViews,
  readTime,
  content,
  speaker,
  speak,
  setDownVoteToggle
}: PostBodySubHeaderProps) {
  const location = useLocation();

  const publishedViaLeo = useMemo(() => {
    if (!metadata) return false;

    metadata = JSON.parse(metadata);

    if (!metadata?.app) return false;
    const app = metadata.app.split("/")?.[0];
    return app === "leofinance" || app === "leothreads";
  }, [metadata]);

  return (
    <div className="flex items-center justify-start gap-x-2 py-2.5 border-y border-solid border-pri dark:border-pri-d">
      {publishedViaLeo && (
        <span className="flex pl-1">
          <LeoFinanceBadge />
          <span className="text-pri/60 dark:text-pri-d/60 ml-2">·</span>
        </span>
      )}
      <div className="flex items-center">
        <div className="flex items-center gap-x-1.5 h-fit rounded-lg text-pri/60 dark:text-pri-d/60 text-xs text-center">
          <FontAwesomeIcon icon={faEye} />
          {pageViews || 0} views
        </div>
      </div>
      <span className="text-pri/60 dark:text-pri-d/60">·</span>
      <div className="flex items-center">
        <div className="flex h-fit py-1.5 rounded-lg text-pri/60 dark:text-pri-d/60 text-xs text-center">
          {readTime || 0} min read
        </div>
      </div>
      {content?.body && (
        <span className="flex items-center ml-auto gap-x-1.5">
          <button
            id="ShareTwitter"
            type="button"
            aria-label="Share on X"
            className="flex justify-center items-center w-8 h-8 rounded-full hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150"
            onClick={() => {
              window.open(
                `https://x.com/intent/post?url=https://inleo.io${location.pathname}&text=${content.title}`,
                "_blank"
              );
            }}
          >
            <span className="w-4 h-4">
              <svg
                viewBox="0 0 24 24"
                width="100%"
                height="100%"
                fill="currentColor"
              >
                <g>
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                </g>
              </svg>
            </span>
          </button>

          <Popover anchorId="ShareTwitter" content="Share on X" />

          <button
            id="speaker-tooltip"
            className="flex justify-center items-center w-8 h-8 rounded-full border border-bg-d/40 dark:border-bg/40 text-xs font-medium text-center transition-colors duration-150 hover:bg-pri-d/10 dark:hover:bg-pri/10"
            onClick={() => speak()}
          >
            {speaker === true ? (
              <FontAwesomeIcon icon={faPause} className="ml-[1px]" />
            ) : (
              <FontAwesomeIcon icon={faPlay} className="ml-[1px]" />
            )}
          </button>

          <Popover anchorId="speaker-tooltip" content="Listen" />

          <PostOptions
            postContent={content}
            onDownVoteCallback={setDownVoteToggle}
          />
        </span>
      )}
    </div>
  );
}

interface PostContentProps {
  content: ReactElement<any>;
  className?: string | undefined;
}

export const PostContent = ({ content, className }: PostContentProps) => {
  const parsedBody = useParsedBody(content as any);

  return (
    <div
      className={cn(
        `mt-6 p-6 h-fit prose  dark:prose-invert prose-lg
        sm:prose-sm break-words min-w-full max-w-0
        gap-1 text-lg antialiased !leading-snug`,
        className
      )}
    >
      {parsedBody}
    </div>
  );
};

interface PostFooter {
  children: ReactNode;
}

export const PostFooter = ({ children }: PostFooter) => {
  return <div className="relative flex flex-col">{children}</div>;
};

interface PostPreviewProps {
  url: string;
  author: string;
  permlink: string;
}

export function PostPreview({ url, author, permlink }: PostPreviewProps) {
  const [post, setPost] = useState<PossibleHiveContent>(null);

  useEffect(() => {
    cache
      .getContent({ author, permlink: permlink.replaceAll("#", "") })
      .then(setPost);
  }, [author, permlink]);

  if (post === null) {
    return <LoadingPostPreview />;
  }

  return <FullPostPreview url={url} post={post} />;
}

function removeHtmlMarkdownLinks(inputText: string) {
  const withoutHtml = inputText.replace(/<[^>]*>/g, "");

  const withoutMarkdownLinks = withoutHtml.replaceAll(
    /\[([^\]]+)\]\([^)]+\)/g,
    "$1"
  );

  const withoutLinks = withoutMarkdownLinks.replaceAll(
    /(?:https?|ftp):\/\/[^\s]+/g,
    ""
  );

  return withoutLinks.replaceAll("\n", "");
}
