import { Suspense, lazy, useEffect, useState } from "react";
import { AuthorPerm, calculateReputation } from "~/utils/hive";
import { CachedDiscussion, leocache } from "~/utils/leocache";
import { useParsedBody } from "~/utils/markdown";
import { TimeSincePublish } from "../TimeSincePublish";
import { SmallAvatar10, SmallAvatar12 } from "./SmallAvatar";
import DisplayName from "./DisplayName";
import Reputation from "./Reputation";
import { useNavigate } from "@remix-run/react";
// @ts-ignore
import { ClientOnly } from "remix-utils/client-only";
import ProfileLink from "~/components/ProfileLink";

export default function ReThread({ author, permlink }: AuthorPerm) {
  const [threadData, setThreadData] = useState<null | CachedDiscussion>(null);

  useEffect(() => {
    void (async function () {
      const fetchThread = await leocache.singleThread({
        author,
        permlink
      } as AuthorPerm);
      setThreadData(fetchThread[0] || null);
    })();
  }, [setThreadData]);

  const onPreviewClick = (ev: MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    ev.stopImmediatePropagation();
    ev.stopPropagation();
    ev.preventDefault();
  };

  const parsedBody = useParsedBody(threadData?.body || "") || "";

  if (threadData === null) return null;
  return (
    <>
      <ClientOnly>
        {() => (
          <span
            onClick={onPreviewClick}
            className="rethread relative block w-full border my-2 border-pri rounded-xl dark:border-pri-d px-4 py-4 pb-1 z-[99] overflow-hidden text-sm text-pri dark:text-pri-d hover:bg-pri-hov dark:hover:bg-pri-hov-d transition-colors duration-150 antialiased"
          >
            <span className="flex flex-row">
              <span className="flex flex-col">
                <span className="flex flex-row items-center gap-3">
                  <span className="relative flex shrink-0">
                    <SmallAvatar12 author={threadData?.author || author} />
                    <span className="absolute -right-1 -bottom-1">
                      <Reputation
                        reputation={calculateReputation(
                          threadData?.author_reputation
                        )}
                        className="text-xs max-w-[24px] max-h-[24px]"
                      />
                    </span>
                  </span>
                  <span className="flex flex-col">
                    <Suspense fallback={<></>}>
                      <ProfileLink
                        children={
                          <DisplayName
                            className="font-bold text-md dark:text-white sans-serif justify-center"
                            name={threadData?.author}
                          />
                        }
                        accountName={threadData?.author}
                      />
                    </Suspense>
                    <TimeSincePublish
                      publishTime={threadData?.created}
                      utc={false}
                      className="text-xxs pt-0.5 tracking-normal mt-1"
                    />
                  </span>
                </span>
              </span>
            </span>

            <span className="-mt-4 mb-0 leading-snug prose prose-sm dark:prose-invert overflow-hidden">
              {parsedBody}
            </span>
          </span>
        )}
      </ClientOnly>
    </>
  );
}

export function LoadedPreview({ data }) {
  const navigate = useNavigate();
  const onPreviewClick = (ev: MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    ev.preventDefault();
    return navigate(`/threads/view/${data.author}/${data.permlink}`);
  };

  const { body, author, author_reputation, created, permlink } = data;

  const parsedBody = useParsedBody(body || "") || "";

  return (
    <div
      onClick={onPreviewClick}
      className="flex flex-col cursor-pointer border border-pri rounded-xl dark:border-pri-d px-4 py-4 gap-3 z-1000 overflow-hidden text-sm text-pri dark:text-pri-d hover:bg-pri-hov dark:hover:bg-pri-hov-d transition-colors duration-150 *:no-underline"
    >
      <span className="flex flex-row">
        <span className="flex flex-col pb-5">
          <span className="flex flex-row items-center gap-3">
            <span className="relative flex shrink-0">
              <SmallAvatar10 author={author || author} />
              <span className="absolute -right-1 -bottom-1">
                <Reputation
                  reputation={author_reputation}
                  className="text-xxs max-w-[24px] max-h-[24px]"
                />
              </span>
            </span>
            <span className="flex flex-col">
              <Suspense fallback={<></>}>
                <ProfileLink
                  children={
                    <DisplayName
                      name={author}
                      className="font-bold text-md dark:text-white sans-serif justify-center"
                    />
                  }
                  accountName={author}
                  referrer={author}
                />
              </Suspense>
              <TimeSincePublish
                publishTime={created}
                utc={false}
                className="text-xxs pt-0.5 tracking-normal leading-none"
              />
            </span>
          </span>
        </span>
      </span>

      <span className="-mt-4 mb-0 overflow-hidden">{parsedBody}</span>
    </div>
  );
}
