import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, Link } from "@remix-run/react";
import {
  useContext,
  useState,
  useEffect,
  useMemo,
  Suspense,
  lazy,
  memo
} from "react";
import { AccountsContext, ActivePostViewContext } from "~/contexts";
import { useScotContent, useSimpleAnalytics } from "~/hooks/contentHooks";
import { PostInteractions } from "~/components/posts/PostInteractions";
import { useHydrated } from "remix-utils/use-hydrated";
import { ClientOnly } from "remix-utils/client-only";
import { BookmarkIcon } from "../bookmarks/BookmarkIcon";
import { useAppStore } from "~/store";
import { useParsedBody } from "~/utils/markdown";
import {
  PossibleThreadContent,
  ThreadContent,
  ThreadIndexed
} from "~/utils/thread";
import AccountName from "../format/AccountName";
import DisplayName from "../format/DisplayName";
import LikeCount, {
  useLikeToggleState,
  useLiked,
  LikeSlider,
  DownVoteSlider
} from "../format/LikeCount";
import { PollOptions } from "../format/Polls";
import ReplyCount from "../format/ReplyCount";
import Reputation from "../format/Reputation";
import { SmallAvatar12, SmallAvatar14 } from "../format/SmallAvatar";
import ImageViewer from "../ImageViewer";
import Payout from "../Payout";
import Popover from "../Popover";
import { TimeSincePublish } from "../TimeSincePublish";
import LoadOnlyReply, {
  ThreadBody,
  ThreadFooter,
  ThreadHeader,
  ThreadText
} from "./ThreadViewHelpers";
import { LoadingIndicator } from "./LoadingIndicator";
import LoadingIndicatorBadge from "../badges/LoadingIndicatorBadge";
import { fetchAccount } from "~/utils/hive";
import { Spinner } from "../format/Spinner";
import ProfileLink from "~/components/ProfileLink";
import ThreadOptions from "~/components/threads/ThreadOptions";
import PollView from "~/components/format/PollView";
import { cache } from "~/utils/cache";

interface FullThreadProps {
  threadContent: ThreadContent;
  inView?: boolean;
  liveMode?: boolean;
  continues?: boolean;
  norecursive?: boolean;
  scrolledTo?: boolean;
}

function DeletedFocusThread({ threadIndexed }) {
  const { author } = threadIndexed;
  return (
    <ThreadFocusContainer>
      <ThreadFocusHeader>
        <SmallAvatar12 author={author} />
        <AccountName author={author} />
      </ThreadFocusHeader>
      <ThreadBody>
        <span className="text-red-500 dark:text-red-800">
          This thread is deleted.
        </span>
      </ThreadBody>
    </ThreadFocusContainer>
  );
}

interface ThreadViewProps {
  threadContent: PossibleThreadContent;
  inView: boolean;
  liveMode?: boolean;
  scrolledTo?: boolean;
}

interface ThreadHeaderProps {
  children: React.ReactNode;
}

interface LoadingThreadProps {
  threadIndexed: ThreadIndexed;
}

function ThreadFocusHeader({ children }: ThreadHeaderProps) {
  return <div className="flex gap-x-3">{children}</div>;
}

function LoadingFocusThread({ threadIndexed }: LoadingThreadProps) {
  const { author, children } = threadIndexed;

  return (
    <ThreadFocusContainer>
      <ThreadFocusHeader>
        <SmallAvatar12 author={author} />
        <ThreadHeader>
          <AccountName author={author} />
          <Reputation reputation={0} />
        </ThreadHeader>
      </ThreadFocusHeader>
      <ThreadBody>
        <LoadingIndicator />
        <ThreadFooter>
          <ReplyCount replyCount={children} />
          <LikeCount likeCount={0} scotContent={null} />
        </ThreadFooter>
      </ThreadBody>
    </ThreadFocusContainer>
  );
}

export const ThreadFocusView = memo(({ threadContent }: ThreadViewProps) => {
  if (!threadContent) return <FullFocusThreadLoading />;
  if (!!threadContent?.thread?.deleted!) {
    return <DeletedFocusThread threadIndexed={threadContent.thread} />;
  }
  if (threadContent?.content === null) {
    return <LoadingFocusThread threadIndexed={threadContent.thread} />;
  }
  return (
    <FullFocusThread
      threadContent={threadContent as ThreadContent}
      inView={false}
    />
  );
});
ThreadFocusView.displayName = "ThreadFocusView";

function ThreadFocusContainer({ children }) {
  return (
    <div className="flex flex-col px-3 py-2 border-b border-pri dark:border-pri-d gap-y-3">
      {children}
    </div>
  );
}

const FullFocusThreadLoading = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.reload(true); // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
    }, 10000);
  }, []);
  return (
    <div className="flex flex-col gap-4">
      <Spinner text={""} />
    </div>
  );
};

export function FullFocusThread({ threadContent }: FullThreadProps) {
  const { content, thread } = threadContent;
  if (!thread) return <FullFocusThreadLoading />;
  const { author, permlink, children, fake } = thread;
  const {
    author_reputation: reputation,
    body,
    active_votes,
    created
  } = content;

  const parsedBody = useParsedBody(body);
  const accounts = useContext(AccountsContext);

  const [
    activeAccount,
    tokenPrices,
    threadcasts,
    loadingIndicator,
    { activePost, setActivePost }
  ] = useAppStore(store => [
    store.account.activeAccount,
    store.wallet.tokenPrices,
    store.discussion.threadcasts,
    store.loadingIndicator,
    store.threads
  ]);

  const isNotificationsPage = useLocation().pathname === "/notifications";

  const account = accounts?.[author];
  const [state, scotContent, setScotContent, fetchScottContent] =
    useScotContent(thread, 0);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [reputationScore, setReputationScore] = useState(0);

  useEffect(() => {
    if (!account) {
      fetchAccount(author).then(acc => {
        setReputationScore(acc.reputation);
      });
    }
  }, [author, account]);

  useEffect(() => {
    isNotificationsPage ||
      (setActivePost && setActivePost({ author: account }));
    return () => isNotificationsPage || (setActivePost && setActivePost(null));
  }, [account]);

  const [downvoteToggle, setDownvoteToggle] = useState(false);
  const [likeToggle, setLikeToggle] = useLikeToggleState();
  const liked = useLiked(
    activeAccount?.name ?? null,
    scotContent,
    thread?.stats!
  );

  const isActive = activeAccount !== null;

  let metadata: { metadata: any; isPoll: boolean; pollOptions: PollOptions };

  try {
    metadata = JSON.parse(content.json_metadata);
  } catch {
    metadata = {};
  }

  const authorPerm = { author, permlink };

  const isPoll = metadata.isPoll && metadata.pollOptions;

  const isParentThread = content.parent_author === "leothreads";

  const { pageViews } = useSimpleAnalytics(
    `/threads/view/${author}/${permlink}`
  );

  const images = useMemo(() => {
    if (!metadata) return [];

    const links =
      metadata.links?.filter((link: string) =>
        link?.startsWith?.("https://images")
      ) || [];

    const imagesFromMetadata = metadata.images || [];

    const modifyImageUrls = (url: string) => {
      return url.replaceAll(")", "").replaceAll("(", "");
    };

    const merged = links.concat(imagesFromMetadata).map(modifyImageUrls);

    return merged;
  }, [metadata]);

  const [shortDetails, setShortDetails] = useState({});

  const isShortsVideo = content?.body?.includes(
    "https://3speak.tv/watch?v=leoshorts"
  );
  console.log({ shortDetails, isShortsVideo });

  let regex, match;

  if (isShortsVideo) {
    try {
      regex = /https:\/\/3speak\.tv\/watch\?v=([^\/]+)\/([^\/]+)/;
      match = (content?.body || "")?.match(regex) || ["", "", ""];
    } catch {
      match = ["", "", ""];
    }
  } else {
    match = ["", "", ""];
  }

  const [, shortsAuthor, shortsPermlink] = match;

  useEffect(() => {
    void (async function () {
      const scotContent = await cache.getScotContent({
        author: shortsAuthor,
        permlink: shortsPermlink?.split(" ")[0].replaceAll(" ", "")?.trim()
      });
      console.log(shortsPermlink);
      const hiveContent = await cache.getContent({
        author: shortsAuthor,
        permlink: shortsPermlink?.split(" ")[0].replaceAll(" ", "")?.trim()
      });

      setShortDetails({
        scotContent,
        hiveContent
      });
    })();
  }, [shortsAuthor, shortsPermlink, isShortsVideo]);

  const handleImageClick = (e: React.MouseEvent) => {
    if (
      (e.target as HTMLElement).tagName !== "IMG" ||
      (e.target as HTMLElement).nodeName !== "IMG" ||
      isViewerOpen
    )
      return;

    const index = images.findIndex(img =>
      (e.target as HTMLImageElement).src.includes(img)
    );

    setCurrentIndex(index);
    setIsViewerOpen(true);
  };

  const in_threadcast = threadcasts?.find?.(
    t => t.author === author || t.replies.find(r => r.split("/")[0] === author)
  );

  const MemoizedThreadOptions = useMemo(
    () => (
      <Suspense fallback={<></>}>
        <ThreadOptions
          threadContent={{ thread, content }}
          onDownVoteCallback={setDownvoteToggle}
        />
      </Suspense>
    ),
    [content?.permlink]
  );
  return (
    <div id="post" onClick={e => handleImageClick(e)}>
      <ThreadFocusContainer>
        {!isParentThread && (
          <div className="relative overflow-hidden h-[150px] rounded-lg border border-pri dark:border-pri-d">
            <Link
              prefetch="intent"
              to={`/threads/view/${content.parent_author}/${content.parent_permlink}`}
              className="z-[15] absolute w-full h-full select-none cursor-pointer"
            ></Link>
            <ClientOnly>
              {() => (
                <LoadOnlyReply
                  author={content.parent_author}
                  permlink={content.parent_permlink}
                  notop={false}
                  continues={false}
                />
              )}
            </ClientOnly>
          </div>
        )}

        <ThreadFocusHeader>
          <div className="relative flex">
            <SmallAvatar14 author={author} />
            {!in_threadcast && (
              <div className="absolute z-10 -right-0.5 -bottom-0.5">
                <Reputation reputation={reputationScore} />
              </div>
            )}
          </div>
          <div className="flex flex-col justify-center">
            <ThreadHeader>
              <Suspense fallback={<></>}>
                <ProfileLink accountName={author} referrer={content.permlink}>
                  <DisplayName authorName={author} name={content?.author} />
                </ProfileLink>
              </Suspense>
            </ThreadHeader>
            <div className="flex flex-row items-center gap-1">
              <Suspense fallback={<></>}>
                <ProfileLink accountName={author}>
                  <AccountName author={author} />
                </ProfileLink>
              </Suspense>
              <span className="flex text-gray-500"> · </span>
              <TimeSincePublish publishTime={created} utc={false} />
            </div>
            {loadingIndicator.items.find(
              item =>
                item.type === "edit" &&
                item.category === "thread" &&
                item.id == permlink
            ) && (
              <LoadingIndicatorBadge
                operationType="edit"
                operationCategory="thread"
              />
            )}
          </div>

          <div className="flex items-center justify-center ml-auto">
            {activeAccount && MemoizedThreadOptions}
          </div>
        </ThreadFocusHeader>
        <ThreadBody>
          <ThreadText>
            {parsedBody}
            {isPoll &&
              (activeAccount ? (
                <Suspense fallback={<></>}>
                  <PollView
                    author={activeAccount?.name}
                    authorPerm={authorPerm}
                    pollOptions={metadata?.pollOptions}
                  />
                </Suspense>
              ) : (
                <>
                  <div className="relative opacity-70       flex flex-1 flex-col max-w-[420px] my-2 mx-auto gap-y-2 p-1.5 rounded-2xl border border-pri dark:border-pri-d">
                    <div className="absolute flex w-full h-full items-center justify-center align-center self-center">
                      <div className="self-center z-[1] m-auto font-semibold text-lg text-pretty">
                        To view the poll please{" "}
                        <a className="z-[1]" href="/login">
                          {" "}
                          login.{" "}
                        </a>
                      </div>
                    </div>
                    <div className="blur-sm flex flex-1 flex-col">
                      <button
                        type="button"
                        className="flex flex-1 justify-center items-center py-2.5 px-5 rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium"
                      >
                        1
                      </button>
                      <button
                        type="button"
                        className="flex flex-1 justify-center items-center py-2.5 px-5 rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium"
                      >
                        2
                      </button>
                      <button
                        type="button"
                        className="flex flex-1 justify-center items-center py-2.5 px-5 rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium"
                      >
                        3
                      </button>
                      <button
                        type="button"
                        className="flex flex-1 justify-center items-center py-2.5 px-5 rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] font-medium"
                      >
                        4
                      </button>
                      <div className="flex flex-1 justify-between items-center gap-x-4 px-1 text-pri/50 dark:text-pri-d/50">
                        <small className="text-xs">0 votes</small>
                        <div className="flex items-center gap-x-1.5">
                          <button
                            type="button"
                            className="flex text-xs text-pri/50 dark:text-pri-d/50"
                          >
                            Edit
                          </button>
                          <span className="text-xs font-bold">·</span>
                          <button
                            type="button"
                            className="flex text-xs text-red-500 font-semibold"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </ThreadText>
          <ThreadFooter>
            <PostInteractions className="relative">
              <ReplyCount replyCount={children} threadContent={threadContent} />
              <LikeCount
                likeCount={
                  thread?.stats?.total_votes! ||
                  active_votes?.length ||
                  scotContent?.active_votes?.length ||
                  0
                }
                scotContent={scotContent}
                liked={liked}
                setLikeToggle={isActive ? setLikeToggle : undefined}
              />
              <div
                id={`analytics-buybacks-${author}-${permlink}`}
                className="group flex py-0.5 pl-0.5 pr-2 rounded-full text-gray-500 dark:text-zinc-500 hover:bg-acc/[.15] dark:hover:bg-acc/[.15] hover:text-acc dark:hover:text-acc transition-colors cursor-pointer"
              >
                <div className="group flex items-center text-gray-500 dark:text-zinc-500 hover:text-acc dark:hover:text-acc transition-colors cursor-pointer">
                  <Popover
                    anchorId={`analytics-buybacks-${author}-${permlink}`}
                    content={
                      <div>
                        This thread generated approximately $
                        {(pageViews * 0.0018).toFixed(2)} or{" "}
                        {(
                          (pageViews * 0.0018) /
                            tokenPrices?.["wrapped-leo"]?.usd || 0
                        ).toFixed(2) || "-"}{" "}
                        LEO <br /> in LeoAds Revenue for LEO smart contract
                        buybacks.
                      </div>
                    }
                    place="top"
                    style={{
                      zIndex: 99999
                    }}
                  />
                  <div className="flex justify-center items-center w-9 h-9 sm:w-8 sm:h-8 rounded-full">
                    <FontAwesomeIcon
                      icon={faChartSimple}
                      className="w-5 h-5"
                      fixedWidth
                    />
                  </div>
                  <span className="pl-1 font-medium text-lg sm:text-base">
                    {pageViews || 0}
                  </span>
                </div>
              </div>
              <BookmarkIcon author={author} permlink={content?.permlink} />
              {!fake ? (
                <Payout
                  state={state}
                  scotContent={
                    isShortsVideo ? shortDetails?.scotContent : scotContent
                  }
                  hiveContent={
                    isShortsVideo ? shortDetails?.hiveContent : content
                  }
                  fetchScottContent={fetchScottContent}
                />
              ) : (
                <div />
              )}
              {isActive && (
                <LikeSlider
                  likeToggle={likeToggle}
                  setLikeToggle={setLikeToggle}
                  setScotContent={setScotContent}
                  accountName={activeAccount?.name}
                  authorPerm={thread}
                />
              )}

              <DownVoteSlider
                likeToggle={downvoteToggle}
                setLikeToggle={setDownvoteToggle}
                setScotContent={setScotContent}
                accountName={activeAccount?.name}
                authorPerm={thread}
              />
            </PostInteractions>
          </ThreadFooter>
        </ThreadBody>

        <ImageViewer
          visible={isViewerOpen}
          images={images}
          currentIndex={currentIndex}
          onClose={() => setIsViewerOpen(false)}
        />
      </ThreadFocusContainer>
    </div>
  );
}
